import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import VueClipboard from 'vue-clipboard2'

const plugins: Plugin = (_ctx, _inject) => {
  // Inject Vue modules
  Vue.use(VueClipboard)
}

export default plugins
