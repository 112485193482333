import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      name: ''
    }
  },
  computed: {
    show: {
      get (): boolean {
        const res = this.$store.getters['modals/name'] === this.name
        console.debug(`GET [${this.name}]: ${res}`)
        return res
      },
      set (v: boolean): void {
        console.debug(`SET [${this.name}]: ${v}`)
        if (v) {
          this.$store.dispatch('modals/show', {
            name: this.name,
            data: this.$store.getters['modals/data']
          })
        } else {
          this.$store.dispatch('modals/hide')
        }
      }
    }
  }
})
