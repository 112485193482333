import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from './index'
import { AlertsOptions, AlertsState } from '~/types/alerts'
import { updatePosition } from '~/utils/misc'

// noinspection JSUnusedGlobalSymbols
export const state: () => AlertsState = () => ({
  show: false,
  options: { type: 'success' }
})

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<AlertsState, RootState> = {
  visible: s => s.show,
  options: s => s.options
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<AlertsState, RootState> = {
  show ({
    commit,
    state
  }, options: AlertsOptions) {
    if (typeof options === 'undefined') {
      options = { type: 'success' }
    }
    if (state.show) {
      if (state.options.resolve) {
        state.options.resolve(true)
      }
      commit('hide')
    }
    return new Promise((resolve, reject) => {
      options.resolve = resolve
      options.reject = reject
      commit('show', options)
      updatePosition()
    })
  },
  hide ({ commit }) {
    commit('hide')
  }
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<AlertsState> = {
  show (state, options: AlertsOptions) {
    state.options = options || ({} as AlertsOptions)
    state.show = true
  },
  hide (state) {
    state.show = false
  }
}
