// noinspection TypeScriptUnresolvedVariable

import type { Plugin } from '@nuxt/types'

const vkPixel: Plugin = function ({ app: { router } }) {
  function create () {
    // @ts-ignore
    VK.Retargeting.Init('<%= options.id %>')
    // @ts-ignore
    router.afterEach((_, _2) => {
      // @ts-ignore
      VK.Retargeting.Hit()
    })
  }

  // @ts-ignore
  if (window.VK && window.VK.Retargeting) {
    create()
  } else {
    (function (w, c) {
      (w[c] = w[c] || []).push(create)
    })(window, 'vkAsyncInitCallbacks')
  }
}
export default vkPixel
