import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from './index'
import { Lootbox, LootboxesState, LootboxOpened, LootboxStats } from '~/types/lootboxes'

// noinspection JSUnusedGlobalSymbols
export const state: () => LootboxesState = () => ({
  lootboxes: []
})

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<LootboxesState, RootState> = {
  lootboxes: s => s.lootboxes
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<LootboxesState, RootState> = {
  async getLootboxes ({ commit, rootGetters }) {
    try {
      const lootboxes: Lootbox[] = []
      for (const modpack of rootGetters['core/modpacks']) {
        const res = await this.$axios.get('/minecraft/lootboxes/', { params: { modpack_id: modpack.id } })
        const data: Lootbox[] = res.data.lootboxes
        lootboxes.push(...data)
      }
      commit('setLootboxes', lootboxes)
    } catch (e: unknown) {
      console.error(e)
    }
  },
  async getLootboxesOpened (_, ctx?: { lootbox_id?: number, server_id?: number }) {
    try {
      const res = await this.$axios.get<{ lootboxes: LootboxOpened[], count: number }>('/minecraft/lootboxes/opened/', {
        params: {
          lootbox_id: ctx?.lootbox_id,
          server_id: ctx?.server_id
        }
      })
      return res.data
    } catch (e: unknown) {
      console.error(e)
    }
    return []
  },
  async getLootboxStats (_, ctx: { lootbox_id: number }): Promise<LootboxStats> {
    try {
      const res = await this.$axios.get('/minecraft/lootboxes/stats/', { params: { lootbox_id: ctx.lootbox_id } })
      return res.data.stats
    } catch (e: unknown) {
      console.error(e)
    }
    return {
      total_opened: 0,
      rate_up_guarantee: 0,
      since_last_tier_3: 0,
      since_last_tier_4: 0,
      tier_1_dusted: 0,
      tier_1_gained: 0,
      tier_2_dusted: 0,
      tier_2_gained: 0,
      tier_3_dusted: 0,
      tier_3_gained: 0,
      tier_4_dusted: 0,
      tier_4_gained: 0
    } as LootboxStats
  }
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<LootboxesState> = {
  setLootboxes (state, lootboxes: Lootbox[]) {
    state.lootboxes.splice(0, state.lootboxes.length, ...lootboxes)
  }
}
