export const Alerts = () => import('../../components/Alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const CodeInput = () => import('../../components/CodeInput.vue' /* webpackChunkName: "components/code-input" */).then(c => wrapFunctional(c.default || c))
export const DownloadModal = () => import('../../components/DownloadModal.vue' /* webpackChunkName: "components/download-modal" */).then(c => wrapFunctional(c.default || c))
export const EmptyNav = () => import('../../components/EmptyNav.vue' /* webpackChunkName: "components/empty-nav" */).then(c => wrapFunctional(c.default || c))
export const HelpModal = () => import('../../components/HelpModal.vue' /* webpackChunkName: "components/help-modal" */).then(c => wrapFunctional(c.default || c))
export const LandingNav = () => import('../../components/LandingNav.vue' /* webpackChunkName: "components/landing-nav" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const ListNav = () => import('../../components/ListNav.vue' /* webpackChunkName: "components/list-nav" */).then(c => wrapFunctional(c.default || c))
export const LoaderModal = () => import('../../components/LoaderModal.vue' /* webpackChunkName: "components/loader-modal" */).then(c => wrapFunctional(c.default || c))
export const MarkdownView = () => import('../../components/MarkdownView.vue' /* webpackChunkName: "components/markdown-view" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.ts' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModpackModal = () => import('../../components/ModpackModal.vue' /* webpackChunkName: "components/modpack-modal" */).then(c => wrapFunctional(c.default || c))
export const ModpackRulesModal = () => import('../../components/ModpackRulesModal.vue' /* webpackChunkName: "components/modpack-rules-modal" */).then(c => wrapFunctional(c.default || c))
export const NeedAuth = () => import('../../components/NeedAuth.vue' /* webpackChunkName: "components/need-auth" */).then(c => wrapFunctional(c.default || c))
export const PermInfoModal = () => import('../../components/PermInfoModal.vue' /* webpackChunkName: "components/perm-info-modal" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const Steve = () => import('../../components/Steve.vue' /* webpackChunkName: "components/steve" */).then(c => wrapFunctional(c.default || c))
export const Box3d = () => import('../../components/box3d.vue' /* webpackChunkName: "components/box3d" */).then(c => wrapFunctional(c.default || c))
export const IconDiscord = () => import('../../components/icon/IconDiscord.vue' /* webpackChunkName: "components/icon-discord" */).then(c => wrapFunctional(c.default || c))
export const IconVK = () => import('../../components/icon/IconVK.vue' /* webpackChunkName: "components/icon-v-k" */).then(c => wrapFunctional(c.default || c))
export const IconYoutube = () => import('../../components/icon/IconYoutube.vue' /* webpackChunkName: "components/icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const LandingAdminApply = () => import('../../components/landing/AdminApply.vue' /* webpackChunkName: "components/landing-admin-apply" */).then(c => wrapFunctional(c.default || c))
export const LandingBannerNews = () => import('../../components/landing/BannerNews.vue' /* webpackChunkName: "components/landing-banner-news" */).then(c => wrapFunctional(c.default || c))
export const LandingBonus = () => import('../../components/landing/Bonus.vue' /* webpackChunkName: "components/landing-bonus" */).then(c => wrapFunctional(c.default || c))
export const LandingDonate = () => import('../../components/landing/Donate.vue' /* webpackChunkName: "components/landing-donate" */).then(c => wrapFunctional(c.default || c))
export const LandingGuides = () => import('../../components/landing/Guides.vue' /* webpackChunkName: "components/landing-guides" */).then(c => wrapFunctional(c.default || c))
export const LandingHero = () => import('../../components/landing/Hero.vue' /* webpackChunkName: "components/landing-hero" */).then(c => wrapFunctional(c.default || c))
export const LandingLootboxes = () => import('../../components/landing/Lootboxes.vue' /* webpackChunkName: "components/landing-lootboxes" */).then(c => wrapFunctional(c.default || c))
export const LandingModpack = () => import('../../components/landing/Modpack.vue' /* webpackChunkName: "components/landing-modpack" */).then(c => wrapFunctional(c.default || c))
export const LandingOurProjects = () => import('../../components/landing/OurProjects.vue' /* webpackChunkName: "components/landing-our-projects" */).then(c => wrapFunctional(c.default || c))
export const LootboxPrize = () => import('../../components/lootbox/lootbox-prize.vue' /* webpackChunkName: "components/lootbox-prize" */).then(c => wrapFunctional(c.default || c))
export const Lootbox = () => import('../../components/lootbox/lootbox.vue' /* webpackChunkName: "components/lootbox" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
