import { Plugin } from '@nuxt/types'
import { Loader, LoaderOptions } from '~/types/loader'

const plugin: Plugin = ({ store }, inject) => {
  const loader: Loader = (promise, opts) => {
    const options: Required<LoaderOptions> = Object.assign({
      immediate: false,
      timeout: 2000
    }, opts || {})
    const fin = () => new Promise<void>((resolve) => {
      setTimeout(() => {
        store.commit('loader/setStatus', null)
        resolve()
      }, options.timeout)
    })

    store.commit('loader/setStatus', 0)

    return new Promise((resolve, reject) => {
      promise.then((response) => {
        store.commit('loader/setStatus', 1)
        if (options.immediate) {
          fin().then()
          resolve(response)
        } else {
          fin().then(() => {
            resolve(response)
          })
        }
      }).catch((err) => {
        store.commit('loader/setStatus', -1)
        if (options.immediate) {
          fin().then()
          reject(err)
        } else {
          fin().then(() => {
            reject(err)
          })
        }
      })
    })
  }
  inject('loader', loader)
}

// noinspection JSUnusedGlobalSymbols
export default plugin
