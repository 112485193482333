export default `Настоящее Пользовательское соглашение (далее – Соглашение) регулирует отношения между ЦИФРАЗИЕЙ - владельцем интернет-ресурса <a href="https://cifrazia.com">cifrazia.com</a> - и любым физическим лицом - Пользователем интернет-ресурса <a href="https://cifrazia.com">cifrazia.com</a>.

1 ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
1.1 Аккаунт - создаваемая Пользователем на Интернет-ресурсе учетная запись, представляющая собой совокупность данных о Пользователе, необходимых для использования Пользователем Игры в соответствии с настоящим Соглашением, а именно, авторизации Пользователя в Игре, доступа к информации о Пользователе, настройкам, отображении неактивированного программного продукта и иной подобной информации.
1.2 Игра - онлайн игра Minecraft ©, являющаяся программой для ЭВМ и представляющая собой совокупность данных, команд и порождаемых ею аудиовизуальных отображений («данные» и «команды»), активируемых последовательно для получения Пользователем определённого результата, предусмотренного сценарием Игры, без внесения платы или после внесения платежа (неактивированный программный продукт). Все исключительные права на Игру принадлежат Mojang AB (далее – Правообладатель).
1.3 Игровой магазин - это внутриигровой сервис, расположенный на Интернет-ресурсе, в котором, посредством неактивированного программного продукта, Пользователь может приобрести доступ к предметам Игры.
1.4 Игровой сервер - это программный компонент вычислительной системы, обеспечивающий связь между различными Пользователями, предоставляющий им возможность коммуникации друг с другом в рамках программной оболочки Игры.
1.5 Интернет-ресурс - соответствующий сайт <a href="https://cifrazia.com">cifrazia.com</a> в сети интернет, посвящённый Игре. Интернет-ресурс не является официальным сайтом и продуктом Minecraft, не утвержден Mojang, не связан с Mojang, не поддерживается Mojang.
1.6 Клиентская часть Игры - программное обеспечение, подлежащее установке на персональный компьютер или иное техническое устройство Пользователя, необходимое для использования Игры и Лаунчера Игры, включая получение прав использования данных и команд, размещенных на серверах Правообладателя и ЦИФРАЗИИ, включая неактивированный программный продукт.
1.7 Контент - это любое информационно значимое наполнение Интернет-ресурса, включая форум, чат, описательную страницу игровых серверов, личный кабинет Пользователя, программное обеспечение и прочее.
1.8 Лаунчер Игры - программный интерфейс, исключительные права на использование которого принадлежат ЦИФРАЗИИ, позволяющий, скачивать, загружать и запускать Клиентскую часть Игры и программные файлы, получать доступ к серверной части Игры, сохранять настройки, проверять целостность файлов Игры, производить проверку обновлений системы.
1.9 Неактивированный программный продукт - это право, возникающее у Пользователя после внесения, установленного ЦИФРАЗИЕЙ платежа, отображаемое в Аккаунте в виде внутриигровой валюты, которое дает Пользователю возможность получения в Игровом магазине премиум-техник и/или виртуальных предметов для использования в Игре и для достижения Пользователем определенного результата, предусмотренного сценарием Игры.
1.10 Пользователь - это физическое лицо, которое является конечным потребителем контента Интернет-ресурса и/или игроком в Игре.
1.11 Правила поведения Пользователя - полностью именуемые – Правила поведения Пользователя во время Игры, на сайте и в группах в социальных сетях <a href="https://cifrazia.com">cifrazia.com</a> от «02» августа 2019 г. Расположены по следующему адресу.
1.12 Стороны/Сторона - Пользователь и ЦИФРАЗИЯ именуемые совместно или отдельно в тексте настоящего Соглашения.
1.13 ЦИФРАЗИЯ - владелец Интернет-ресурса, а именно общество ЦИФРАЗИЯ в лице ИП Парфенов Богдан Сергеевич (ИНН 632133307833), который самостоятельно и по своему усмотрению определяет порядок использования Интернет-ресурса, в том числе порядок размещения информации (контента) на нем.
Если из контекста не вытекает иное, при употреблении в настоящем Соглашении терминов в единственном числе также подразумевается множественное число и наоборот.

2 ПРЕДМЕТ СОГЛАШЕНИЯ
2.1 ЦИФРАЗИЯ предоставляет Пользователю доступ в полном объеме к контенту, расположенному на Интернет-ресурсе, а также неактивированному программному продукту, на условиях настоящего Соглашения, при условии соблюдения Пользователем требований и условий, установленных Правовым документами.
2.2 Пользователь соглашается с тем, что Игра, а также контент на Интернет-ресурсе, могут содержать звуковые и видеоэффекты, которые при определенных обстоятельствах могут вызывать обострение эпилептических и иных расстройств неврологического характера у склонных к ним лиц, и подтверждает, что не страдает указанными расстройствами, а в противном случае не будет использовать Интернет-ресурс и Игру.
2.3 Пользователь понимает и соглашается с тем, что регулярное длительное нахождение у персонального компьютера или иного технического устройства может вызывать различные нарушения здоровья, в том числе ослабление зрения, сколиоз, различные формы неврозов и тому подобное. Пользователь гарантирует, что он будет использовать Интернет-ресурс и Игру исключительно на протяжении разумного времени с перерывами на отдых и профилактику нарушений здоровья.
2.4 Перед тем, как создать Аккаунт Пользователь обязан ознакомиться со следующими документами:
Соглашение на обработку персональных данных,
настоящее Пользовательское соглашение от «02» августа 2019 г.,
Правилам поведения Пользователя от «02» августа 2019 г

(далее – Правовые документы)
Пользователь не освобождается от обязательств, предусмотренных настоящим Соглашением, если он не ознакомился Правовыми документами.
В случае, если Пользователь не согласен с положениями Правовых документов, то он обязан немедленно прекратить использовать Интернет-ресурс.
2.5 Пользователь гарантирует, что обладает дееспособностью для заключения настоящего Соглашения. В случае, если Пользователь не достиг возраста совершеннолетия, он обязан самостоятельно получить необходимо разрешение в требуемой законом форме от своих родителей либо законных представителей.
2.6 Пользователь не вправе использовать Интернет-ресурс и Игру, если законодательство территории его местонахождения запрещает использовать данный Интернет-ресурс и Игру или устанавливает иные, в том числе возрастные, ограничения на использование Интернет-ресурсов и Игры. В этом случае ответственность за использование Интернет-ресурса и Игры несет Пользователь.
2.7 Интернет-ресурс и Игра могут содержать ссылки на интернет-сайты, программы, фотоматериалы, видеоматериалы, аудиоматериалы, графические и текстовые материалы, принадлежащие третьим лицам, включая рекламодателей. Пользователь понимает и настоящим соглашается с тем, что размещение ссылок на интернет-сайты или материалы третьих лиц на Интернет-ресурсе и в Игре не означает, что ЦИФРАЗИЯ поддерживает, одобряет или рекомендует указанные сайты или материалы. Пользователь использует сайты, программное обеспечение и материалы третьих лиц исключительно на свой риск, в том числе во время игрового процесса.
2.8 Момент начала использования Интернет-ресурса, либо нажатия кнопки «Зарегистрироваться», либо нажатия кнопки «Скачать Cifrazia Desktop», либо аналогичной, что по смыслу ст.ст. 435 и 438 Гражданского кодекса РФ является принятием (акцептом) оферты – настоящего Соглашения ЦИФРАЗИИ, а равно заключению договора, что порождает у Пользователя обязанность соблюдать условия Правовых документов.

3 ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ
3.1 Соблюдать условия Правовых документов, размещенных на Интернет-ресурсе. В случае несогласия Пользователя с Правовыми документами Пользователь обязан прекратить использование Интернет-ресурса, в том числе деинсталлировать Клиентскую часть Игры.
3.2 Вправе использовать Игру в пределах, установленных Правообладателем и указанным на сайте Правообладателя по адресу <a href="https://www.mojang.com/">https://www.mojang.com/</a>.
3.3 Вправе использовать только лицензионную версию Игры.
3.4 Использовать Интернет-ресурс, контент, размещенный на нем и неактивированный программный продукт исключительно в пределах, установленных ЦИФРАЗИЕЙ и настоящим Соглашением и Приложением к нему.
3.5 Не нарушать права интеллектуальной собственности Правообладателя в отношении Игры и/или каких-либо составляющих Игры.
3.6 При создании Аккаунта предоставлять соответствующую действительности информацию.
3.7 Обязан самостоятельно отслеживать в Аккаунте корректное отображение неактивированного программного продукта, приобретенного у ЦИФРАЗИИ.
3.8 Самостоятельно принимать меры по обеспечению безопасности Аккаунта и предотвращению его несанкционированного использования третьими лицами. Пользователь обязуется не раскрывать и не передавать третьим лицам свои данные, с помощью которых возможна авторизация (аутентификация) Пользователя на Интернет-ресурсе. В случае, если у Пользователя есть основания считать, что третьи лица получили данную информацию или могут получить, он должен немедленно проинформировать ЦИФРАЗИЮ и изменить свои данные или попросить об этом ЦИФРАЗИЮ.
3.9 Выполнять указания ЦИФРАЗИИ, полученные в индивидуальном или общем порядке на форуме, чате, по электронной почте, через личные сообщения в социальных сетях и другим способом коммуникации. ЦИФРАЗИЯ вправе приостановить, ограничить или прекратить действие настоящего Соглашения, доступ к Аккаунту в случае несоблюдения таких указаний.
3.10 По требованию ЦИФРАЗИИ предоставить или подтвердить информацию, включая персональные данные, а именно фамилию, имя, отчество и контактные данные, необходимые для выполнения условий Соглашения и соблюдения законодательства, а также подтвердить их точность.
3.11 Возместить ЦИФРАЗИИ, другим Пользователям и иным третьим лицам любые убытки, возникшие у них в связи с действиями Пользователя, в том числе из-за нарушения настоящего Соглашения, прав интеллектуальной собственности и иных прав.
3.12 Незамедлительно сообщить ЦИФРАЗИИ о любых фактах несанкционированного использования Аккаунта, взлома и совершения иных подобных действий.
3.13 Самостоятельно нести ответственность за любые материалы и иную информацию, которую Пользователь размещает в Игре, на Интернет-ресурсе, включая чат и форум, или иным образом сообщает другим Пользователем или доводит до всеобщего сведения.
3.14 Соблюдать иные требования и обязательства, предусмотренные Правовыми документами.
3.15 Ограничения пользователя
3.15.1 Пользователь не вправе модифицировать, копировать с целью распространения, транслировать, рассылать, публиковать и иным образом распространять и воспроизводить Лаунчер Игры и Клиентскую часть игры в коммерческих и некоммерческих целях без письменного согласия ЦИФРАЗИИ. Пользователь вправе делать скриншоты экрана компьютера в ходе игрового процесса и распространять их.
3.15.2 Запрещено исследовать программный код, декомпилировать, дизассемблировать, модифицировать, создавать производные продукты, распространять в коммерческих и не коммерческих целях на материальных носителях, а также посредством размещения в сети интернет для доступа и/или скачивания определенными лицами либо неограниченным кругом лиц программное обеспечение, а также его частей и элементов, исключительные права на которое принадлежит ЦИФРАЗИИ, без письменного согласия ЦИФРАЗИИ.

4 ПРАВА И ОБЯЗАННОСТИ ЦИФРАЗИИ
4.1 ЦИФРАЗИЯ обязана:
4.1.1 Обеспечить Пользователю возможность использовать весь контент и программное обеспечение, размещенные на Интернет-ресурсе на условиях, изложенных в настоящем Соглашении, при условии соблюдения Пользователем Правовых документов.
4.1.2 Предоставить Пользователю возможность участвовать/присоединиться к Игре посредством Лаунчера Игры.
4.1.3 Предоставить Пользователю неактивированный программный продукт при внесении Пользователем платежа, установленного ЦИФРАЗИЕЙ.
4.1.4 Предоставить Пользователю возможность получения (скачивания) Клиентской части Игры с использованием Лаунчера.
4.1.5 Уведомлять Пользователя об изменении условий настоящего Соглашения, путем размещения его новой версии на Интернет-ресурсе.
4.2 ЦИФРАЗИЯ вправе:
4.2.1 В любое время без уведомления Пользователя в одностороннем порядке ограничить, расширить, дополнить, модифицировать или иным образом изменить контент Интернет-ресурса и программное обеспечение, исключительные права на которое принадлежат ЦИФРАЗИИ, а также любые его элементы и части.
4.2.2 В любое время изменить, удалить любую информацию, размещенную Пользователем на Интернет-ресурсе и группах ЦИФРАЗИИ в социальных сетях.
4.2.3 В любое время без уведомления Пользователя приостановить, ограничить и/или прекратить предоставление права доступа к Интернет-ресурсу, контенту, размещенному на Интернет-ресурсе, и Аккаунту Пользователя на условиях настоящего Соглашения, в том числе при несоблюдении Пользователем условий настоящего Соглашения и Правовых документов. При реализации данного права ЦИФРАЗИЯ не обязана предоставлять Пользователю доказательства, свидетельствующие о нарушении Пользователем Правовых документов, в результате которых Пользователю был прекращен или ограничен доступ.
4.2.4 При длительном периоде неактивности Пользователя удалить Аккаунт Пользователя.
4.2.5 Направлять Пользователю как самостоятельно, так и с привлечением третьих лиц сообщения информационного, технического, рекламного характера (далее – Сообщения) от имени ЦИФРАЗИИ и/или партнеров ЦИФРАЗИИ. Пользователь соглашается получать рассылку таких Сообщений, а также с тем, что такие Сообщения не являются спамом. Пользователь в любое время может отказаться от получения Сообщений ЦИФРАЗИИ путем отправки уведомления в свободной форме на электронный адрес ЦИФРАЗИИ <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>.
4.2.6 Во время игрового процесса делать замечания Пользователю, информировать, предупреждать, уведомлять о несоблюдении Правил поведения Пользователя и настоящего Соглашения. Указания ЦИФРАЗИИ обязательны для Пользователя.
4.2.7 Вправе восстановить Пользователю доступ к Аккаунту и/или неактивированному программному продукту, в результате взлома Аккаунта третьими лицами, при условии если Пользователь докажет факт наличия у него (например, скриншот) неактивированного программного продукта до факта взлома.
4.2.8 В любой момент в одностороннем порядке без предварительного уведомления Пользователя внести изменения в Правовые документы.
4.2.9 Размещать на Интернет-ресурсе рекламу третьих лиц и самостоятельно определять ее характер и количество размещения, при этом при взаимодействии Пользователя и рекламодателя, ЦИФРАЗИЯ не несет ответственности и не возмещает прямой или косвенный ущерб, а также иные денежные средства, который мог понести Пользователь в результате такого взаимодействия.
4.3 Отказ от ответственности ЦИФРАЗИИ.
4.3.1 Пользователь использует контент Интернет-ресурса на свой страх и риск и самостоятельно отвечает за совместимость и исправность программного обеспечения собственного персонального компьютера.
4.3.2 ЦИФРАЗИЯ не несет ответственности за возможные противозаконные действия Пользователя и/или третьих лиц.
4.3.3 ЦИФРАЗИЯ не несет ответственности за утерю Пользователем возможности доступа к Аккаунту, в том числе за утрату логина, пароля и иной информации, а также за неполное, неточное, некорректное указание Пользователем своих данных при создании аккаунта на Интернет-ресурсе.
4.3.4 ЦИФРАЗИЯ не несет ответственности за высказывания Пользователя на Интернет-ресурсе, форуме, чатах, а также группах ЦИФРАЗИИ в социальных сетях, в том числе неуважительное отношение к другим Пользователям.
4.3.5 ЦИФРАЗИЯ не несет ответственности за утрату Пользователем игровых ценностей/предметов, включая неактивированный программный продукт.
4.3.6 ЦИФРАЗИЯ не несет ответственности за отсутствие у Пользователя доступа в интернет и качество услуг интернет-провайдеров.
4.3.7 ЦИФРАЗИЯ не несет ответственности за материалы третьих лиц, размещенных в Игре, а также на сайтах третьих лиц, доступных по ссылкам с Интернет-ресурса и/или групп ЦИФРАЗИИ в социальных сетях.
4.3.8 ЦИФРАЗИЯ не несет ответственности за прямой или косвенный ущерб, а также упущенную выгоду Пользователя или третьих лиц в результате использования или невозможности использования Игры и Интернет-ресурса, заявлений и поведения третьих лиц в Игре, на Интернет-ресурсе и группах ЦИФРАЗИИ в социальных сетях, а также несанкционированного доступа любых третьих лиц к личной информации Пользователя, включая Аккаунт Пользователя.
4.3.9 ЦИФРАЗИЯ не несет ответственности за то, что контент Интернет-ресурса и программное обеспечение будут удовлетворять требованиям и ожиданиям Пользователя.
4.3.10 ЦИФРАЗИЯ не несет ответственности за то, что доступ к Игре посредством Лаунчера, доступ к контенту Интернет-ресурса будут предоставляться непрерывно, быстро, надежно и без ошибок.
4.3.11 В любом случае, ответственность ЦИФРАЗИИ ограничивается исключительно размером документально подтвержденных фактических убытков, которые возникли у Пользователя в результате виновных действий и/или бездействий ЦИФРАЗИИ.

5 ФОРС-МАЖОР
5.1 Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему Соглашению, если неисполнение явилось прямым следствием обстоятельств непреодолимой силы таких, как наводнение, пожар, землетрясение и других стихийных бедствий, война или военные действия, действия государственных органов, если эти обстоятельства непосредственно повлияли на исполнение настоящего Соглашения. Если такие обстоятельства непосредственно повлияли на срок исполнения обязательств, установленный в Соглашении, то этот срок соразмерно отодвигается на время действия соответствующих обстоятельств. Сторона, которая в связи с форс-мажорными обстоятельствами не может исполнить свои обязательства, обязана не позднее 10 (десяти) рабочих дней с момента их наступления уведомить об этом другую Сторону посредством электронной почты.
ПРИОБРЕТЕНИЕ НЕАКТИВИРОВАННОГО ПРОГРАММНОГО ПРОДУКТА

6.1 По желанию Пользователя ЦИФРАЗИЯ предоставляет Пользователю возможность возмездного получения неактивированного программного продукта, который обеспечивает Пользователю дополнительные, расширенные, специальные возможности Игры.
6.2 По желанию Пользователя ЦИФРАЗИЯ предоставляет Пользователю возможность возмездного получения неактивированного программного продукта, который обеспечивает Пользователю дополнительные, расширенные, специальные возможности Игры.
6.3 Воспользоваться неактивированным программным продуктом Пользователь может лишь при внесении установленного платежа на расчетный счет ЦИФРАЗИИ.
6.4 Платеж за неактивированный программный продукт осуществляются Пользователем посредством оператора платежных систем, который определяется ЦИФРАЗИЕЙ в одностороннем порядке и указывается в Игровом магазине, либо иным способом.
6.5 Размер оплаты, способы и условия приобретения неактивированного программного продукта определяется ЦИФРАЗИЕЙ в одностороннем порядке и указываются в Игровом магазине либо в иной форме, в частности на интернет-страницах оператора платежных систем.
6.6 ЦИФРАЗИЯ вправе изменить размер платежа за неактивированный программный продукт в одностороннем порядке в любое время без предварительного уведомления Пользователя
6.7 Пользователь, при совершении им платежа, обязуется следовать инструкциям соответствующих операторов платежных систем и правилам оплаты, установленных ЦИФРАЗИЕЙ. Пользователю предоставляется неактивированный программный продукт исключительно при правильном исполнении и соблюдении правил оплаты.
6.8 Пользователь обязан указать достоверные данные при совершении платежа и самостоятельно нести все риски, связанные с неверным указанием данных при осуществлении им платежа.
6.9 ЦИФРАЗИЯ не несет ответственности за правильность выполнения Пользователем условий проведения платежа за неактивированный программный продукт, как находящуюся вне контроля ЦИФРАЗИИ
6.10 По всем вопросам, связанным с порядком и правилами совершения платежа, возврата ошибочно оплаченных денежных средства, либо платежа, совершенного с нарушениями требований законодательства РФ, Пользователь обязан обращаться напрямую к оператору платежных систем.
6.11 Оператором платежных систем может взиматься комиссия сверх платы, установленной ЦИФРАЗИЕЙ. Пользователь понимает и соглашается, что при совершении платежа посредством некоторых операторов платежных систем итоговая сумма оплаты за неактивированный программный продукт может отличаться от размера оплаты, установленного ЦИФРАЗИЕЙ, и ЦИФРАЗИЯ не возмещает Пользователю, возникшую в результате этого разницу между суммой, установленной им за неактивированный программный продукт и суммой фактически совершенного Пользователем платежа посредством некоторых операторов платежных систем.
6.12 Размер платы, установленный ЦИФРАЗИЕЙ в Игровом магазине либо на интернет-ресурсах операторов платежных систем может быть выражен в двух и более валютах. Пользователь понимает и соглашается с тем, что при произведении оплаты посредством отдельных операторов платежных систем может применяться курс обмена валюты, отличный от того, который используется ЦИФРАЗИЕЙ, поэтому выставленная Пользователю сумма к оплате может отличаться от суммы платежа, указанной ЦИФРАЗИЕЙ, и ЦИФРАЗИЯ не возмещает Пользователю, возникшую в результате этого курсовую разницу.
6.13 ЦИФРАЗИЯ не несет ответственность за отклонение платежа Пользователя оператором платежных систем или при совершении оператором платежных систем ошибки.
6.14 Моментом оплаты неактивированного программного продукта считается дата и время зачисления денежных средств Пользователя на расчетный счет ЦИФРАЗИИ и возврату Пользователю не подлежат. Пользователю предоставляется неактивированный программный продукт только после оплаты соответствующего продукта в полном объеме. ЦИФРАЗИЯ вправе не предоставлять Пользователю неактивированный программный продукт до получения подтверждения об оплате данного продукта в полном объеме.
6.15 Неактивированный программный продукт считается предоставленным Пользователю с момента отображения его у Пользователя в Аккаунте, и предоставляется и до момента использования его по назначению, либо на срок действия Соглашения, если иное не предусмотрено Правовыми документами, сценарием Игры, либо не вытекает из характера самого неактивированного программного продукта.
6.16 В случае, если неактивированный программный продукт является внутриигровым платежным средством, то конвертация, полученных денежных средств Пользователя, происходит по курсу и формуле, установленных ЦИФРАЗИЕЙ и опубликованными на Интернет-ресурсе. Врутриигровое платежное средство зачисляется на личный счет в Аккаунте Пользователя. Внутриигровое платежное средство Пользователь вправе использовать в Игровом магазине на приобретение иных неактивированных программных продуктов – предметов для использования в Игре. Пользователь соглашается и принимает, что приобретенные в Игровом магазине иные неактивированные программные продукты за внутриигровое платежное средство не подлежат возврату в Игровой магазин и обмену на другие неактивированные программные продукты, а также обмену на наличные и безналичные денежные средства.
6.17 В случае, если в результате технической ошибки, сбоя Игры, либо сознательных действий Пользователя, Пользователю был предоставлен неактивированный программный продукт без оплаты, либо не полной оплаты, либо в большем количестве, чем фактически совершенный Пользователем платеж, то Пользователь обязан незамедлительно сообщить об этом ЦИФРАЗИИ любым удобным ему способом и совершить причитающийся ЦИФРАЗИИ платеж, если Пользователь начал использовать неактивированный программный продукт. ЦИФРАЗИЯ вправе самостоятельно, без уведомления Пользователя и без какой-либо компенсации, устранить последствия такой технической ошибки, в том числе удалить неактивированный программный продукт из Аккаунта Пользователя, либо удержать стоимость из любых других поступлений денежных средств от Пользователя, на какие бы цели эти денежные средства не предназначались.
6.18 ЦИФРАЗИЯ вправе приостановить или полностью прекратить действие Соглашения в одностороннем порядке и без уведомления Пользователя, если имеется основание полагать, что Пользователь совершил неправомерные действия, связанные с оплатой неактивированного программного продукта, а также имеет право передать соответствующую информацию в правоохранительные органы для проведения проверки.
6.19 Пользователь обязан компенсировать ЦИФРАЗИИ, а также третьим лицам все убытки, которые возникли в связи с неправомерным поведением Пользователя, в том числе при использовании им для оплаты не принадлежащих ему денежных средств.
6.20 Пользователь понимает и соглашается с тем, что в случае истечения срока действия или использования неактивированного программного продукта, расторжения какой-либо из Сторон настоящего Соглашения по основаниям, предусмотренным настоящим Соглашением, а также удаления Аккаунта по волеизъявлению Пользователя, возмещение ЦИФРАЗИЕЙ денежных средств, оплаченных Пользователем за неактивированный программный продукт, не производится.
6.21 ЦИФРАЗИЯ не осуществляет обмен за наличные и безналичные денежные средства неактивированного программного продукта, приобретенного Пользователем.
6.22 Пользователь самостоятельно несет ответственность за сохранность документов, подтверждающих оплату неактивированного программного продукта, и в случае запроса ЦИФРАЗИИ, обязан предоставить их в течение 48 (сорока восьми) часов.
6.23 В случае получения Пользователем предложения от любых третьих лиц, связанных с приобретением у них неактивированного программного продукта, Пользователь обязан незамедлительно уведомить о таком факте ЦИФРАЗИЮ. Если Пользователь, в нарушении положения данного пункта Соглашения, произвел оплату по вышеуказанному предложению с использованием указанных платежных данных, претензии Пользователя к ЦИФРАЗИИ по поводу не поступления неактивированного программного продукта в Аккаунт Пользователя не принимаются и потраченные таким образом денежные средства Пользователю ЦИФРАЗИЕЙ не компенсируются.
6.24 В случае, если ЦИФРАЗИЯ установит, что Пользователь получает какие-либо услуги или неактивированный программный продукт как внутри Игры, так и на Интернет-ресурсе от третьих лиц, действующих без соглашения с ЦИФРАЗИЕЙ, ЦИФРАЗИЯ вправе приостановить, ограничить или расторгнуть настоящее Соглашение, а также заблокировать Аккаунт Пользователя без уведомления последнего.
6.25 ЦИФРАЗИЯ не несет ответственности за использование Пользователем не принадлежащих ему денежных средств и средств платежа.
6.26 Пользователь не вправе передавать неактивированный программный продукт в коммерческих и некоммерческих целях третьим лицам, в том числе путем передачи Аккаунта.

7 ПРАВА ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ
7.1 Все исключительные права на Игру, включая, но не ограничиваясь: на исходный и объектный код, геймплей, пользовательский интерфейс, графические изображения, фотографии, анимации, видеоизображения, видеоклипы, звуковые записи, звуковые эффекты, музыка, текстовое наполнение и содержание Игры, также любые другие элементы Игры принадлежат Правообладателю.
7.2 Пользователь вправе размещать на Интернет-ресурсе любые материалы, включая, но не ограничиваясь указанным перечнем: письменный контент, фотографии, графику, изображения, иллюстрации, логотипы, видео, flash-анимацию. Размещая вышеуказанный контент, Пользователь гарантирует, что он свободен от прав третьих лиц. При размещении контента, исключительные права на который, принадлежат третьим лицам, Пользователь обязан размещать данный контент с соблюдением предоставленных прав и уведомить об этом ЦИФРАЗИЮ посредством направления уведомления на электронную почту <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>. В случае возникновения претензий третьих лиц о размещенном Пользователем контенте на Интернет-ресурсе ЦИФРАЗИИ, Пользователь обязуется самостоятельно урегулировать возникшие разногласия с третьими лицами и понести все возникшие в результате этого расходы, а также компенсировать расходы ЦИФРАЗИИ в полном объеме, которая она могла понести в результате публикации Пользователем контента, права на который принадлежат третьим лицам.
7.3 В момент размещения контента, за исключением контента, указанного в п. 7.2. настоящего Соглашения, Пользователь безвозмездно передает ЦИФРАЗИИ права на анонимное использование, доведение до всеобщего сведения, воспроизведение, публичный показ, распространение любым удобным ЦИФРАЗИЕЙ способом, сообщение в эфир и по кабелю, перевод, переработку, внесение изменений, дополнений, комментариев и подобное, бессрочное хранение контента Пользователя.
7.4 Права на контент на Интернет-ресурсе, за исключением контента, указанного в пп.7.1-7.3. настоящего Соглашения, принадлежат ЦИФРАЗИИ. Пользователь не вправе его копировать, изменять, публиковать, передавать, продавать, видоизменять и прочее без письменного согласия ЦИФРАЗИИ
7.5 Пользователю запрещается копировать HTML-код Интернет-ресурса или какой-либо иной код Интернет-ресурса для создания web-страниц.
7.6 При пользовании Интернет-ресурсом Пользователь может столкнуться с контентом, способным причинить вред или ущерб, являющимся непристойным или оскорбительным, а также содержащим неточную информацию. Пользователь берет на себя все риски, связанные с использованием такого контента. ЦИФРАЗИЯ имеет право удалить любой контент, который нарушает настоящее Соглашение и Приложение к нему, или является оскорбительным, но не возлагает на себя обязательства по удалению такого контента.

8 СРОК ДЕЙСТВИЯ НАСТОЯЩЕГО СОГЛАШЕНИЯ
8.1 Соглашение вступает в силу с момента совершения Пользователем действий, указанных в п.2.8. настоящего Соглашения и действует неопределенный срок, если оно не было расторгнуто по инициативе одной из Сторон.

9 ИЗМЕНЕНИЕ И РАСТОРЖЕНИЕ НАСТОЯЩЕГО СОГЛАШЕНИЯ
9.1 Настоящее Соглашение, включая Приложение к нему, может быть изменено ЦИФРАЗИЕЙ в одностороннем порядке, в любое время и без предварительного уведомления Пользователя.
9.2 Изменения Соглашения, включая Приложение к нему, вступают в силу в течение 48 (сорока восьми) часов с даты и времени публикации на Интернет-ресурсе, если иное прямо не указано в Соглашении.
9.3 Пользователь не освобождается от обязательств, предусмотренных настоящим Соглашением, с учетом всех внесенных в него изменений, а так же изменений в Приложение, если Пользователь не ознакомился с изменениями в Соглашении, включая Приложение к нему.
9.4 Пользователь не вправе использовать доступ к Игре, посредством Лаунчера, Интернет-ресурс, контент, размещенный на нем, если он не согласен с изменениями, внесенными в Соглашение, включая Приложение к нему. В этом случае Пользователь обязан известить ЦИФРАЗИЮ в течение 48 (сорока восьми) часов, направив электронное письмо по адресу <a href="mailto:help@cifrazia.com">help@cifrazia.com</a> с уведомлением о своем несогласии с изменениями и расторжении настоящего Соглашения. ЦИФРАЗИЯ, после получения уведомления Пользователя обязана приостановить доступ Пользователя к Аккаунту. При этом ЦИФРАЗИЯ не осуществляет возврат каких-либо денежных средств и не производит иных возмещений Пользователю, в том числе убытков.
9.5 Если по истечении 48 (сорока восьми) часов Пользователь не изъявил желания расторгнуть настоящее Соглашение, Пользователь считается принявшим условия настоящего Соглашения с учетом внесенных в него изменений, включая изменения в Приложение
9.6 ЦИФРАЗИЯ вправе в любое время расторгнуть настоящее Соглашение в одностороннем внесудебном порядке с прекращением возможности использовать Пользователем Интернет-ресурс и Аккаунт, включая неактивированный программный продукт, в случае любого, в том числе однократного нарушения Пользователем условий настоящего Соглашения, а также условий Правовых документов. Пользователь принимает и соглашается, что в этом случае ЦИФРАЗИЯ не возвращает Пользователю денежные средства и не возмещает убытки.
9.7 Пользователь вправе в любое время без уведомления ЦИФРАЗИИ и без объяснения причин расторгнуть настоящее Соглашение в одностороннем внесудебном порядке путем удаления Аккаунта, и/или удаления Клиентской части Игры, с сохранением обязательств Пользователя, возникших до такого удаления. При этом, все неактивированные программные продукты, привязанные к данному Аккаунту, могут быть удалены без права на восстановление. Пользователь принимает и соглашается, что в этом случае ЦИФРАЗИЯ не возвращает Пользователю денежные средства и не возмещает убытки.

10 ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
10.1 В случае, если имеются расхождения между настоящим Соглашением и иными Правовыми документами, то преимущественную силу имеют положения настоящего Соглашения.
10.2 В случае возникновения споров Стороны примут все меры для их разрешения путем двухсторонних переговоров. В случае, если согласие не будет достигнуто путем переговоров, все споры, разногласия и конфликты, возникающие в связи с исполнением настоящего Соглашения и Правовых документов, будут разрешаться в судебном порядке по местонахождения ЦИФРАЗИИ.
10.3 Во всём, что не предусмотрено настоящим Соглашением, Стороны руководствуются действующим законодательством РФ.

Приложение:
1 Правила поведения Пользователя во время игры, на сайте и в группах в социальных сетях <a href="https://cifrazia.com">cifrazia.com</a> от «02» августа 2019 г.

Реквизиты исполнителя 

Название организации
ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ ПАРФЕНОВ БОГДАН СЕРГЕЕВИЧ

ИНН
632133307833

ОГРН
319631300142246
`
