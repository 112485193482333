export default `Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всех персональных данных, которые ЦИФРАЗИЯ может получить о Пользователе во время использования сайта с доменным именем <a href="https://cifrazia.com">cifrazia.com</a>.

1 ОСНОВНЫЕ ПОНЯТИЯ
1.1 ЦИФРАЗИЯ – владелец сайта, который самостоятельно и по своему усмотрению определяет порядок использования сайта, в том числе порядок размещения информации (контента) на нем.
1.2 Конфиденциальность персональных данных - обязательное для выполнения ЦИФРАЗИЕЙ или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных за исключением случаев, когда передача без согласия субъекта персональных данных предусмотрена российским или иным применимым законодательством в рамках, установленной законодательством, процедуры.
1.3 Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых ЦИФРАЗИЕЙ с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных и передачу третьим лицам в соответствии с договорными отношениями с ЦИФРАЗИЕЙ.
1.4 Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
1.5 Политика конфиденциальности - настоящий документ со всеми изменениями и дополнениями, расположенный в сети Интернет по адресу <a href="https://cifrazia.com">cifrazia.com</a>
1.6 Пользователь - дееспособное физическое дееспособное лицо, использующее Сайт в собственных интересах. В случае, если Пользователь не достиг возраста совершеннолетия, Пользователь гарантирует, что им получено разрешение в требуемой законом форме от своих родителей либо законных представителей.
1.7 Сайт — ресурс, размещенный в сети интернет по адресу <a href="https://cifrazia.com">cifrazia.com</a>.
1.8 Форма регистрации – это заполняемая форма на Сайте, в которой Пользователь указывает свои Персональные данные для получения расширенного доступа к информации на Сайте.
1.9 Файлы Cookies — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере Пользователя, который веб-браузер каждый раз пересылает веб-серверу в составе HTTP-запроса при попытке открыть страницу соответствующего сайта.
1.10 IP-адрес — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.

2 ОБЩИЕ ПОЛОЖЕНИЯ
2.1 Настоящая Политика конфиденциальности применяется только к информации, получаемой в ходе использования Сайта с доменным именем <a href="https://cifrazia.com">cifrazia.com</a>.
2.2 ЦИФРАЗИЯ не проверяет достоверность Персональных данных, предоставляемых Пользователем.
2.3 Использование Пользователем Сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.
2.4 Принимая данную Политику конфиденциальности, Пользователь тем самым дает свое согласие ЦИФРАЗИИ на обработку своих Персональных данных, указанных в пп. 3.3.-3.4. данной Политики конфиденциальности, в том числе сбор, запись, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных в целях, указанных в п. 4.1. Также принимая данную Политику конфиденциальности, Пользователь тем самым дает свое согласие ЦИФРАЗИИ на передачу третьим лицам согласно договорным отношениям следующие функции: сбор, запись, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
2.5 Если Пользователь не согласен с условиями Политики конфиденциальности, он обязан прекратить использование Сайта.

3 ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ
3.1 Настоящая Политика конфиденциальности устанавливает обязанности ЦИФРАЗИИ по обработке Персональных данных Пользователей, их охране, в том числе обеспечению режима защиты конфиденциальности Персональных данных, которые Пользователь предоставляет ЦИФРАЗИИ при регистрации на Сайте.
3.2 ЦИФРАЗИЯ собирает два вида информации о Пользователе:
3.2.1 информацию, которую Пользователь сознательно предоставил ЦИФРАЗИИ в процессе использования Сайта;
3.2.2 техническую информацию, автоматически собираемую программным обеспечением Сайта во время его посещения Пользователем.
3.3 Персональные данные предоставляются Пользователем при регистрации на Сайте и включают в себя следующие сведения:
3.3.1 имя,
3.3.2 фамилию (по желанию);
3.3.3 адрес электронной почты.
3.4 Техническая информация, автоматически собираемая программным обеспечением Сайта во время его посещения Пользователем, включает: 3.4.1 IP адрес;
3.4.2 информацию из cookies;
3.4.3 информацию о браузере;
3.4.4 информация о типе устройства (мобильное или пк);
3.4.5 время доступа.
3.5 На Сайте реализована технология идентификации Пользователей, основанная на использовании файлов cookies. На устройстве, используемом Пользователем для доступа на Сайт, могут быть записаны файлы cookies, которые в дальнейшем будут использованы для автоматической авторизации, для сбора статистических данных, в частности о посещаемости Сайта. ЦИФРАЗИЯ может использовать и раскрывать информацию об использовании Сайта, например, для определения степени использования Сайта, улучшения его контента, объяснения полезности Сайта, а также для расширения функциональности Сайта. Принимая данную Политику конфиденциальности, Пользователь дает свое согласие ЦИФРАЗИИ, что технические данные, указанные в п. 3.4, собираемые с Сайта, могут передаваться по сети Интернет через национальные границы.>
3.6 ЦИФРАЗИЯ не сохраняет Персональные данные в файлах cookies. ЦИФРАЗИЯ использует информацию, записанную в файлах cookies, которая не идентифицирует индивидуальных Пользователей, для анализа тенденций, администрирования Сайта и определения движений Пользователей по Сайту.
3.7 Если Пользователь не хочет, чтобы ЦИФРАЗИЯ собирала техническую информацию о нем с использованием файлов cookies, то Пользователь обязан прекратить пользоваться Сайтом или запретить сохранение файлов cookies на своем устройстве, используемом для доступа к Сайту, соответствующим образом настроив свой браузер. При этом следует иметь в виду, что сервисы сайта, использующие данную технологию, могут оказаться недоступными.
3.8 Персональные данные сохраняются ЦИФРАЗИЕЙ в конфиденциальности.
3.9 ЦИФРАЗИЯ гарантирует, что никогда не предоставляет Персональные данные третьим лицам, за исключением случаев, когда:
3.9.1 передача Персональных данных без согласия Пользователя предусмотрена законодательством РФ;
3.9.2 передача происходит в рамках переноса базы Персональных данных с одного сервиса на другой согласно договорным отношениям ЦИФРАЗИИ;
3.9.3 Пользователь дал свое согласие на передачу Персональных данных;
3.9.4 передача требуется для оказания поддержки обслуживания Пользователя;
3.9.5 передача необходима для обработки заявки Пользователя.

4 ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
4.1 ЦИФРАЗИЯ использует Персональные данные Пользователей в следующих целях:
4.1.1 для обратной связи;
4.1.2 для доступа к форуму и чату;
4.1.3 для доступа к расширенному функционалу Сайта и скачивания Пользователем программного обеспечения, расположенного на Сайте;
4.1.4 для проведения маркетинговых исследований;
4.1.5 для оценки работы Сайта.

5 МЕРЫ ДЛЯ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
5.1 ЦИФРАЗИЯ осуществляет защиту персональной информации Пользователя, применяя общепринятые методы безопасности для обеспечения защиты информации от потери, неправомерного или случайного доступа, искажения и несанкционированного распространения, уничтожения, изменения, блокирования, копирования, а также любых других неправомерных действий с персональными данными третьих лиц. Безопасность реализуется программными средствами сетевой защиты, процедурами проверки доступа, применением криптографических средств защиты информации, соблюдением политики конфиденциальности.

6 СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
6.1 Обработка Персональных данных осуществляется в срок с даты предоставления Персональных данных при регистрации на Сайте Пользователем и до даты отзыва согласия на обработку Персональных данных.
6.2 Если Персональные данные были утрачены либо разглашены, ЦИФРАЗИЯ обязана проинформировать об этом Пользователя.
6.3 ЦИФРАЗИЯ совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.

7 ПРАВА И ОБЯЗАННОСТИ
7.1 Пользователь при пользовании Сайтом обязан предоставить информацию о Персональных данных, требуемую ЦИФРАЗИЕЙ.
7.2 Персональные данные Пользователя, предоставленные при регистрации на Сайте, которые хранятся у ЦИФРАЗИИ и обрабатываются ею, могут быть удалены/обезличены путем обращения Пользователя к ЦИФРАЗИИ, для этого необходимо написать письмо на адрес <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>.
7.3 Пользователь вправе требовать от ЦИФРАЗИИ уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, для этого необходимо написать письмо на адрес <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>.
7.4 ЦИФРАЗИЯ гарантирует, что никогда не предоставляет Персональные данные третьим лицам, за исключением случаев, предусмотренных пунктом 3.9. данной Политики конфиденциальности.
7.5 ЦИФРАЗИЯ обязана использовать полученную информацию исключительно для целей, указанных в п. 4.1 настоящей Политики конфиденциальности.
7.6 ЦИФРАЗИЯ осуществляет блокирование Персональных данных о Пользователе, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
7.7 Пользователь дает согласие на то, что ЦИФРАЗИЯ вправе направлять на указанный им адрес электронной почты новости Сайта, спецпредложениях, рекламу, другую полезную информацию от ЦИФРАЗИИ и/или партнеров ЦИФРАЗИИ. Пользователь в любое время может отказаться от получения писем ЦИФРАЗИИ путем отправки уведомления в свободной форме на электронный адрес <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>.

8 ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
8.1 ЦИФРАЗИЯ имеет право внести любые изменения и дополнения в Политику конфиденциальности в любой момент по своему усмотрению.
8.2 Изменения и дополнения вступают в силу с даты размещения на Сайте Политики конфиденциальности с изменениями и дополнениями.`
