import { Plugin } from '@nuxt/types'
import { NewMarked } from '~/utils/marked/marked'

const plugin: Plugin = (_, inject) => {
  const marked = NewMarked({
    heading: {
      prefix: '',
      link: {
        class: 'md-header-link',
        iconURL: '',
        text: '',
        icon: {
          style: '',
          class: 'mdi mdi-link-variant'
        }
      }
    },
    code: {
      copy: {
        iconURL: '',
        text: '',
        icon: {
          style: 'font-size: 21px',
          class: 'mdi mdi-content-copy'
        },
        button: {
          style: '',
          class: ''
        }
      }
    }
  })
  inject('marked', marked)
}

// noinspection JSUnusedGlobalSymbols
export default plugin
