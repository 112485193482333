import { Plugin } from '@nuxt/types'

import { AlertCaller } from '~/types/alerts'

const plugin: Plugin = ({ store, i18n }, inject) => {
  const makeHappy: AlertCaller = (options) => {
    options = Object.assign({ type: 'success' }, options || {})
    return store.dispatch('alerts/show', options)
  }
  const makeAware: AlertCaller = (options) => {
    options = Object.assign({ type: 'warning' }, options || {})
    return store.dispatch('alerts/show', options)
  }
  const makeSad: AlertCaller = (options) => {
    options = Object.assign({ type: 'error' }, options || {})
    return store.dispatch('alerts/show', options)
  }
  const makeSure: AlertCaller = (options) => {
    options = Object.assign({ type: 'prompt' }, options || {})
    return store.dispatch('alerts/show', options)
  }
  const _ = (text: string): string => {
    const key = `errors.${text}`
    const translated = i18n.t(key)
    if (translated === key) {
      return text
    } else {
      return translated as string
    }
  }
  inject('makeHappy', makeHappy)
  inject('makeAware', makeAware)
  inject('makeSad', makeSad)
  inject('makeSure', makeSure)
  inject('_', _)
}

// noinspection JSUnusedGlobalSymbols
export default plugin
