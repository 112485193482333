import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { Context } from '@nuxt/types'
import _ from 'lodash'
import { localePath } from '~/utils/i18n'
import { callEvery, noWait, sleep, suppress } from '~/utils/misc'

// https://nuxtjs.org/docs/directory-structure/store/#vuex-strict-mode
// noinspection JSUnusedGlobalSymbols
// export const strict: boolean = false

const REDIRECTS: { [key: string]: string } = {
  '/discord': 'https://discord.gg/c5bDM4t'
}

export const state = () => ({})
export type RootState = ReturnType<typeof state>

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<RootState, RootState> = {}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<RootState> = {}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit (_store, ctx: Context) {
    // https://nuxtjs.org/docs/directory-structure/store/#the-nuxtserverinit-action
    // server-side code
    await suppress(ctx.$auth.setStrategy('refresh'))
    await suppress(ctx.$auth.refreshTokens(), (e) => {
      const status = _.get(e, 'response.status', _.get(e, 'code', 500)) as number
      if (status !== 429) {
        console.error(e)
      }
    })

    const i18n = ctx.app.i18n
    const locale = ctx.$cookies.get('i18n') || i18n.defaultLocale
    const path = ctx.route.path

    if (path in REDIRECTS) {
      ctx.redirect(REDIRECTS[path])
      return
    }
    const localizedPath = localePath(path, ctx)
    if (path !== localizedPath) {
      const PromoCode = path.trim().replace(/^\/+|\/+$/g, '')
      if (~PromoCode.search(/^[-a-zA-Z\d_]{1,32}$/)) {
        try {
          const res = await ctx.$axios.get(`/me/auth/sign-up/promo?CODE=${PromoCode}`)
          if (res.data.exists) {
            ctx.$cookies.set('PROMO_CODE', PromoCode, { maxAge: 2592000, path: '/' })
            ctx.redirect(302, localePath('/', ctx))
            return
          }
        } catch (e) {
          console.error(e)
        }
      }
      ctx.redirect(302, localizedPath)
      return
    }
    if (i18n.locale !== locale) {
      await i18n.setLocale(i18n.locale)
    }
  },
  async nuxtClientInit (store, ctx: Context) {
    // https://github.com/potato4d/nuxt-client-init-module
    // client-side code
    noWait(store.dispatch('core/fetch'))
    setInterval(() => {
      noWait(store.dispatch('core/downloadOptionsUpdate'))
    }, 1000 * 10 * 60)
    await sleep(10)
    setTimeout(() => {
      callEvery(() => {
        if (ctx.$auth.loggedIn) {
          ctx.$auth.fetchUser().catch(console.error)
        }
      }, 1000 * 60)
      callEvery(async () => {
        try {
          await ctx.$auth.refreshTokens()
        } catch (e) {
          console.log(e)
        }
      }, 1000 * 60 * 4)
    }, 0)

    console.info(`
%c Не трогайте консоль, если вы не знаете что делаете!`, 'font: 14px bold; font-family: Roboto, Helvetica, Arial, sans-serif; padding: 10px; border-left: 2px solid black;')

    // Set viewport change listener
    const updateAnchor = () => {
      // If scrolled down
      const route = ctx.route
      const location = { path: route.path, hash: route.hash }
      if (window.scrollY > 0) {
        // Parse a[name] from HTML to [(#name, posY)]
        let anchors: Array<[string, number]> = []
        document.querySelectorAll('a[name]').forEach((value) => {
          const i = value as HTMLElement
          const name = '#' + i.getAttribute('name')
          anchors.push([name, i.offsetTop])
        })
        // Sort anchors from lower to upper
        anchors = anchors.sort((a, b) => b[1] - a[1])
        // For each anchor from lower to upper check if scroll passed by
        for (const anchor of anchors) {
          if (window.scrollY >= anchor[1]) {
            if (document.location.hash === anchor[0]) {
              // If anchor already matches then skip
              break
            }
            localStorage.setItem('anchor', anchor[0])
            location.hash = anchor[0]
            if (ctx.app.router) {
              window.history.replaceState(location, '', `${location.path}${location.hash}`)
            }
            break
          }
        }
      } else {
        // If scrolled to top
        // If hash is anchor then erase it
        const el = document.querySelector(`a[name="${document.location.hash.substring(1)}"]`)
        if (el) {
          localStorage.setItem('anchor', '')
          location.hash = ''
          if (ctx.app.router) {
            window.history.replaceState(location, '', `${location.path}${location.hash}`)
          }
        }
      }
    }
    window.addEventListener('scroll', updateAnchor)
    // Set init scroll from document hash
    if (document.location.hash) {
      setTimeout(() => {
        const el = document.querySelector(`a[name="${document.location.hash.substring(1)}"]`)
        if (el) {
          ctx.$scrollTo(el)
        }
      }, 0)
    }

    // Align fixed elements by X/Y axis relative to viewport scroll & size
    const alignElements = () => {
      // Align header
      const header = document.getElementById('HEADER')
      if (header) {
        header.style.left = '-' + window.scrollX + 'px'
      }
      // Align dialogs
      document.querySelectorAll('.v-dialog__content').forEach((value) => {
        const dialog = value as HTMLElement
        dialog.style.left = `calc(max(50%, 520px) - ${Math.max(0, window.scrollX)}px)`
      })
    }
    window.addEventListener('scroll', alignElements)
    window.addEventListener('resize', alignElements)
    setTimeout(alignElements, 0)

    // Detect discord linking
    // const hash = hashObject()
    // if (hash.hasOwnProperty('link_discord')) {
    //   const link_discord = await ctx.$axios.post('/minecraft/user/discord',
    //     {token: hash['access_token']},
    //     {validateStatus: () => true})
    //   if (link_discord.status === 200) {
    //     window.close()
    //   } else {
    //     ctx.$makeAware({text: link_discord.data.toString()}).then().catch()
    //   }
    // }
    console.debug('Initialized')
  }
}
