import { LocaleObject } from '@nuxtjs/i18n/types'
import { Context } from '@nuxt/types'

export function localePath (path: string, ctx: Context) {
  const i18n = ctx.app.i18n
  const i18nLocales = <LocaleObject[]>i18n.locales
  const locale: string = <string>ctx.$cookies.get('i18n') || i18n.defaultLocale || 'ru'
  const locales = '(' + i18nLocales.map(l => l.code).join('|') + ')'

  if (['', '/'].includes(path)) {
    // If Home page w/o locale
    return `/${locale}/`
  } else if (path.match(new RegExp(`^/${locales}$`))) {
    // If Home page w/ locale w/o trailing slash
    return `${path}/`
  } else if (!path.match(new RegExp(`^/${locales}/`))) {
    // If not home page w/o locale
    return `/${locale}${path}`
  } else {
    // If page w/ locale
    return path
  }
}
