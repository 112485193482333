import _ from 'lodash'
import { AxiosResponse } from 'axios'
import { StrDict } from '~/types'

export function hashObject (): StrDict {
  if (typeof document === 'undefined') {
    return {}
  }
  const vars: string[] = document.location.hash.substring(1).split('&')
  const hash: StrDict = {}
  _.forEach(vars, (part) => {
    const parts: string[] = part.split('=')
    const name: string = parts.shift() || ''
    if (name) {
      hash[name] = decodeURIComponent(parts.join('='))
    }
  })
  return hash
}

export interface ApiError {
  error: {
    code: number,
    message: string,
    exception: string,
    caused_by: ApiError | null
  }
}

export type AxiosApiError = {
  response: AxiosResponse<ApiError>
}

export function get_error (err: AxiosApiError | StrDict | any): { code: number, message: string, exception: string } {
  const response = _.get(err, 'response')
  const data: any = _.get(response, 'data')
  if (typeof data !== 'undefined') {
    if (typeof data === 'string' || !_.has(data, 'error')) {
      return {
        code: _.get(response, 'status', 500),
        message: data,
        exception: 'UnknownError'
      }
    } else {
      return response.data.error as { code: number, message: string, exception: string }
    }
  }
  return {
    code: 500,
    message: 'Unknown error',
    exception: 'UnknownError'
  }
}

export function get_error_message (err: AxiosApiError | StrDict | any): string {
  return get_error(err).message
}

export async function suppress<T = any> (promise: Promise<T>, onErr?: (err: Error) => void, onDone?: (res: T) => void): Promise<T | false> {
  try {
    const res = await promise
    if (_.isFunction(onDone)) {
      onDone(res)
    }
    return res
  } catch (err) {
    if (_.isFunction(onErr)) {
      onErr(err as Error)
    } else {
      console.error(err)
    }
    return false
  }
}

export function noWait (p: Promise<any>): void {
  if (typeof p !== 'undefined') {
    p.then().catch(console.error)
  }
}

export function updatePosition () {
  setTimeout(() => {
    const dialogs = <NodeListOf<HTMLElement>>document.querySelectorAll('.v-dialog__content')
    dialogs.forEach((dialog) => {
      dialog.style.left = `calc(max(50%, 520px) - ${Math.max(0, window.scrollX)}px)`
    })
  }, 0)
}

export function isPromise (value: Promise<any> | any) {
  return Boolean(value && typeof value.then === 'function')
}

export function callEvery (callback: Function, timeout: number) {
  setInterval(callback, timeout)
  const res = callback()
  if (isPromise(res)) {
    res.then(console.debug).catch(console.error)
  }
}

export function formatPercent (num: number, sep: string = ','): string {
  if (Number.isInteger(num)) {
    return num.toFixed(0)
  }
  return (Math.round(num * 100) / 100).toString().replace('.', sep)
}

export function sleep (timeout: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function playSound (sound: HTMLAudioElement): Promise<void> {
  sound.pause()
  sound.load()
  // @ts-ignore
  sound.currentTime = 0.0
  return sound.play()
}
