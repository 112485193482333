import Vue from 'vue'
import { Plugin } from '@nuxt/types'

// @ts-ignore
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

const plugins: Plugin = (_ctx, _inject) => {
  // Inject Vue modules
  Vue.use(VueGlide)
}

export default plugins
