import { Plugin } from '@nuxt/types'
import { Moment } from 'moment-timezone'

const plugin: Plugin = ({
  store,
  $moment,
  $vuetify,
  $cookies,
  req
}, inject) => {
  store.commit('core/setThemeDefault', true)
  store.commit('core/setThemeMode', 'dark')
  store.commit('core/setTheme', true)
  store.commit('core/setThemeOSSupport', false)
  store.commit('core/setThemeOSDark', true)

  // const DARK_BY_DEFAULT = $vuetify.theme.dark
  //
  // let themeDark = $cookies.get('themeDark')
  // let themeMode = $cookies.get('themeMode')
  // if ("undefined" === typeof themeDark) themeDark = DARK_BY_DEFAULT
  // if ("undefined" === typeof themeMode) themeMode = 'system'
  // const osThemeSupport = 'undefined' !== typeof window && !(['dark', 'light', 'no-preference'].every(a => {
  //   return !window.matchMedia(`(prefers-color-scheme: ${a})`).matches
  // }))
  //
  // const osThemeDark = (): boolean => {
  //   if (store.getters['core/theme'].osSupport) {
  //     return window.matchMedia('(prefers-color-scheme: dark)').matches
  //       || !window.matchMedia('(prefers-color-scheme: light)').matches
  //   } else {
  //     return DARK_BY_DEFAULT
  //   }
  // }
  // store.commit('core/setThemeDefault', DARK_BY_DEFAULT)
  // store.commit('core/setThemeMode', themeMode)
  // store.commit('core/setTheme', themeDark)
  // store.commit('core/setThemeOSSupport', osThemeSupport)
  // store.commit('core/setThemeOSDark', osThemeDark())

  if (req) {
    // @ts-ignore
    $vuetify.theme.isDark = true
  }

  // if (req) {
  //   // @ts-ignore
  //   $vuetify.theme.isDark = themeDark
  // } else if (osThemeSupport) {
  //   const h = (): void => {
  //     let osDark = osThemeDark()
  //     let t = store.getters['core/theme']
  //     if (t.osDark !== osDark) {
  //       store.commit('core/setThemeOSDark', osDark)
  //     }
  //     if (t.mode === 'system') {
  //       store.commit('core/setTheme', osDark)
  //       $cookies.set('themeDark', osDark)
  //     }
  //   }
  //   if (themeDark !== osThemeDark()) {
  //     themeDark = osThemeDark()
  //     $cookies.set('themeDark', themeDark)
  //   }
  //   $vuetify.theme.dark = themeDark
  //   window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', h)
  //   window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', h)
  //   window.matchMedia('(prefers-color-scheme: no-preference)').addEventListener('change', h)
  // }
  //
  // Vue.use((v) => {
  //   v.mixin({
  //     computed: {
  //       $theme: (): Theme => {
  //         return store.getters['core/theme']
  //       }
  //     }
  //   })
  // })

  // store.watch((state, getters) => getters['core/theme'].dark, (after) => {
  //   $vuetify.theme.dark = after
  // })

  const getMoment = (timestamp: string | number): Moment => {
    if (typeof timestamp === 'number') {
      timestamp *= 1000
    }
    return $moment.utc(timestamp).tz(store.getters['core/timeZone']).locale('ru')
  }
  const _date = (timestamp: string | number): Date => {
    return getMoment(timestamp).toDate()
  }
  inject('getMoment', getMoment)
  inject('date', _date)

  store.commit('core/setTimeZone', $cookies.get('timeZone') || 'UTC')
}

// noinspection JSUnusedGlobalSymbols
export default plugin
