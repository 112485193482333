/* eslint-disable quote-props */
import confidentiality from '~/lang/ru_RU/confidentiality'
import data_handle from '~/lang/ru_RU/data_handle'
import eula from '~/lang/ru_RU/eula'
import international_payments from '~/lang/ru_RU/international_payments.html'
import itemStorage from '~/lang/ru_RU/itemStorage.html'
import minecraft_rules from '~/lang/ru_RU/minecraft_rules'
import payments from '~/lang/ru_RU/payments.html'
import requisites from '~/lang/ru_RU/requisites.html'
import return_policy from '~/lang/ru_RU/return_policy.html'
import support from '~/lang/ru_RU/support.html'
import tos from '~/lang/ru_RU/tos'

// noinspection JSUnusedGlobalSymbols,HtmlUnknownTarget
export default {
  modals: {
    needAuth: {
      title: 'Нужна авторизация',
      text: 'Перед пополнение баланса, необходимо войти в аккаунт',
      signIn: 'Войти',
      signUp: 'Создать аккаунт',
      cancel: 'Отмена'
    },
    gameRules: {
      title: 'Правила игры'
    },
    modpack: {
      gameRules: 'Правила игры',
      info: 'Информация',
      gameVersion: 'Версия игры',
      mapSize: 'Размер карты',
      battleMode: 'Режим сражений',
      wipedAt: 'Вайп был',
      theme: 'Тематика',
      servers: 'Сервера',
      serverOnline: 'ОНЛАЙН',
      admins: {
        admin: 'Адм.',
        moder: 'Модер.',
        helper: 'Хелпер'
      },
      reqs: 'Системные требования',
      modList: 'Список модов',
      addInfo: 'Доп. информация',
      commandList: 'Доступные команды',
      langSupported: 'Поддерживаемые языки',
      langsSupported: 'английский, французский, итальянский, немецкий, испанский, русский, польский, португальский (Бразилия), чешский, китайский (упрощённый), турецкий, корейский'
    },
    alerts: {
      confirm: 'OK',
      success: {
        title: 'Успех!',
        text: 'Операция успешно выполнена'
      },
      warning: {
        title: 'Ошибка!',
        text: 'Что-то пошло не так!'
      },
      error: {
        title: 'Внимание!',
        text: 'Что-то пошло не так!'
      },
      prompt: {
        title: 'Вы уверены?',
        text: 'Данное действие нельзя будет отменить!',
        cancel: 'Отменить',
        confirm: 'Продолжить'
      }
    },
    listNav: {
      profile: 'Профиль',
      settings: 'Настройки',
      minecraft: 'Minecraft',
      minecraftRealms: 'Realms',
      cups: 'Турниры',
      friends: 'Друзья',
      chat: 'Чат',
      partnership: 'Партнёрство',
      documents: 'Документы',
      quit: 'Выйти'
    },
    partnershipApplication: {
      title: 'Заявка на вступление в партнёрскую программу',
      action: 'Подать заявку',
      form: {
        fullName: 'Ваше ФИО',
        fullNamePlaceholder: 'Иванов Иван Иванович',
        blog: 'Ссылка на блог (Youtube, Twitch, Instagram, VK)',
        blogPlaceholder: 'https://vk.com/cifrazia',
        contact: 'Контакт для связи (VK, E-mail, Whatsapp, Telegram)',
        contactPlaceholder: '+79876543210'
      },
      rules: {
        common: {
          empty: 'Это поле обязательное для заполнения',
          short: 'Слишком мало информации',
          long: 'Вы привысили лимит в 255 символов'
        }
      }
    },
    download: {
      title: 'Выберите ОС для загрузки',
      x32: '32 бит',
      x64: '64 бит'
    },
    permInfo: {
      title: 'Дополнительные возможности',
      select: 'Выберите сборку'
    }
  },
  pages: {
    index: {
      subtitle: 'Получай лучшие впечатления от игры на лучших серверах!',
      monitor: {
        label1: 'всего игроков',
        label2: 'игроков онлайн',
        label3: 'рекорд онлайна'
      },
      playBtn: 'НАЧАТЬ ИГРАТЬ',
      rulesBtn: 'Правила',
      bonus: {
        bonus: {
          title: 'Бонусы',
          description_1: 'Получай донат в виде 3',
          description_2: 'за 5 часов игры <br/> ежедневно!'
        },
        vote: {
          title: 'Голосование',
          action: 'голосовать',
          description_1: 'Ты можешь получить до 6',
          description_2: 'ежедневно, голосуя за наш <br/> проект в рейтингах!'
        }
      },
      apply: {
        looking_for: {
          title: 'Набор админ-состава',
          description: 'Мы ищем таланты для работы в команде проекта!'
        },
        apply: 'Подать заявку'
      },
      news: {
        title1: 'ПОСЛЕДНИЕ',
        title2: 'НОВОСТИ',
        dateFormat: 'DD MMMM YYYY',
        page: 'СТРАНИЦА {0}'
      },
      modpacks: {
        title: 'НАШИ СЕРВЕРА',
        modpack: 'МОДПАК',
        gameVersion: 'ВЕРСИЯ ИГРЫ',
        online: 'ОНЛАЙН'
      },
      donate: {
        title: 'Что по донату?',
        perms: {
          header: 'Привилегии',
          abilities: {
            title: 'Способности',
            desc: '10+ команд'
          },
          kits: {
            title: 'Киты',
            desc: '140+ предметов'
          },
          prefix: {
            title: 'Префикс',
            desc: 'Над головой и в табе'
          },
          discount: {
            title: 'Скидки',
            desc: 'На эффекты и карточки'
          }
        },
        lootboxes: {
          title: 'Карты призов',
          subtitle: 'ДО 100+ ПРИЗОВ ДЛЯ КАЖДОГО МОДПАКА',
          soon: 'Скоро',
          how: {
            title: 'Как это работает',
            desc: 'Вам дается выбор из N призов, Вы можете выбрать 1 или несколько, не зная, что в какой карте, а после забрать приз, либо распылить (продать его за золотые монеты).'
          },
          chances: {
            title: 'Вероятности',
            desc: 'Каждое открытие, вероятность выпадения золотого предмета повышается на “X” % и имеет предел. При этом она обнуляется при N открытии / достижении Rate up (гарантированного) предмета.'
          },
          open: 'Открыть карты'
        },
        more: {
          header: 'Также в магазине',
          items: {
            title: 'Предметы',
            desc: '1000+ ПРЕДМЕТОВ В КАЖДОМ МОДПАКЕ'
          },
          abilities: {
            title: 'Способности',
            desc: 'СКОРОСТЬ ПОЛЕТА И ДРУГОЕ ДОСТУПНО ОТДЕЛЬНО'
          },
          effects: {
            title: 'Эффекты',
            desc: 'ПОКУПАЙ ЭФФЕКТЫ КАК У ЗЕЛИЙ ОТ 5 МИНУТ'
          }
        }
      },
      lootbox: {
        title1: 'КАРТЫ ПРИЗОВ',
        title2: '100+ ПРИЗОВ В КАЖДОМ МОДПАКЕ (скоро)',
        description: 'Наборы карт предметов или альтернатива кейсам. ' +
          'Открывайте 3 неизвестные карты и выберите 1 в качестве приза. ' +
          'Затем распылите ее за золотые монеты, либо заберите предмет себе.'
      },
      vote: {
        title1: 'ГОЛОСУЙ',
        title2: 'ЗА НАШ ПРОЕКТ',
        hint: 'Награда за голосование зачисляется автоматически на игровой счёт пользователя'
      }
    },
    signIn: {
      title: 'Авторизация',
      form: {
        login: 'Введите никнейм / E-mail',
        pass: 'Введите пароль',
        remember: 'Запомнить меня',
        forgot: 'Забыли пароль?',
        create: 'Создать аккаунт',
        action: 'Войти'
      },
      code: {
        title1: {
          sending: 'На Вашу почту <b>{0}</b> отправлено письмо с кодом подтверждения регистрации.',
          mobile: 'В мобильном приложении (Google Authenticator / Authy) сгенерирован код.',
          unknown: 'Данный режим двухфакторной авторизации не поддерживатся на сайте.'
        },
        title2: 'Введите код подтверждения для завершения авторизации'
      },
      rules: {
        login: {
          empty: 'Пожалуйста введите никнейм / E-mail',
          short: 'Слишком короткий никнейм / E-mail',
          longEmail: 'Слишком длинный E-mail',
          malformed: 'Недопустимый никнейм / E-mail'
        },
        pass: {
          empty: 'Пожалуйста введите пароль',
          short: 'Слишком короткий пароль',
          long: 'Слишком длинный пароль'
        }
      },
      back: 'Назад'
    },
    restoreAccess: {
      title: 'Восстановление доступа',
      enter: '       Отмена       ',
      back: '      Вернуться     ',
      form: {
        login: 'Введите никнейм / E-mail',
        pass: 'Введите новый пароль',
        pass2: 'Повторите новый пароль',
        action: 'Восстановить'
      },
      rules: {
        login: {
          empty: 'Пожалуйста введите никнейм / E-mail',
          short: 'Слишком короткий никнейм / E-mail',
          longEmail: 'Слишком длинный E-mail',
          malformed: 'Недопустимый никнейм / E-mail'
        },
        pass: {
          empty: 'Пожалуйста введите пароль',
          short: 'Слишком короткий пароль',
          long: 'Слишком длинный пароль'
        },
        pass2: {
          empty: 'Пожалуйста введите пароль',
          mismatch: 'Пароли не совпадают'
        }
      },
      code: {
        title1: 'На почту <b>{0}</b> отправлено письмо с кодом подтверждения.',
        title2: 'Введите код подтверждения для завершения восстановления'
      }
    },
    signUp: {
      title: 'Регистрация',
      next: 'Продолжить',
      form: {
        username: 'Введите никнейм',
        email: 'Введите E-mail',
        pass: 'Введите пароль',
        pass2: 'Повторите пароль',
        invite: 'Код приглашения',
        subscribe: 'Подписаться на рассылку',
        create: 'Создать аккаунт',
        action: 'Войти'
      },
      enter: 'У меня уже есть аккаунт',
      cancel: 'Отмена',
      back: 'Назад',
      ToS: {
        label: 'Ознакомьтесь с пользовательским соглашением',
        check: 'Я ознакомился и согласен с пользовательским соглашением',
        alert: '*Прочитайте пользовательское соглашение до конца',
        popup: {
          title: 'Вы не прочли пользовательское соглашение',
          text: 'Для продолжение регистрации, Вы должны согласиться с пользовательским соглашением.',
          confirm: 'Согласиться',
          cancel: 'Отмена'
        }
      },
      code: {
        title1: 'На почту <b>{0}</b> отправлено письмо с кодом подтверждения регистрации.',
        title2: 'Введите код подтверждения для завершения регистрации'
      },
      rules: {
        username: {
          empty: 'Пожалуйста введите никнейм',
          short: 'Слишком короткий никнейм (мин. 3)',
          long: 'Слишком длинный никнейм (макс. 24)',
          malformed1: 'Никнейм должен начинаться с буквы, цифры, - или _',
          malformed2: 'Никнейм содержит недопустимые символы'
        },
        email: {
          empty: 'Пожалуйста введите E-mail',
          short: 'Слишком короткий адрес',
          long: 'Слишком длинный адрес',
          malformed: 'Недопустимый Email адрес'
        },
        pass: {
          empty: 'Пожалуйста введите пароль',
          short: 'Слишком короткий пароль',
          long: 'Слишком длинный пароль'
        },
        pass2: {
          empty: 'Пожалуйста введите пароль',
          mismatch: 'Пароли не совпадают'
        },
        invite: {
          malformed: 'Недопустимый код приглашения'
        }
      }
    },
    profile: {
      nav: {
        index: 'Общее',
        security: 'Безопасность',
        notifications: 'Уведомления',
        confidential: 'Конфиденциальность',
        theme: 'Тема',
        sideMenu: 'Меню',
        broadcast: 'Эфир'
      },
      socials: {
        title: 'СОЦИАЛЬНЫЕ СЕТИ:',
        link: 'Привязать',
        unlink: 'Отвязать'
      },
      index: {
        title1: 'Общие настройки аккаунта',
        action1: 'ВЫЙТИ',
        settings: {
          account: {
            title: 'Аккаунт:',
            label1: 'Никнейм:',
            label2: 'Пароль:',
            label3: 'Эл. почта:',
            label4: 'Дата рождения:'
          },
          region: {
            title: 'Регион:',
            label1: 'Язык:',
            label2: 'Сервера:',
            label3: 'Время:'
          },
          content: {
            title: 'Настройки контента',
            label1: 'Авто-подгрузка Фото:',
            label2: 'Авто-подгрузка Видео:',
            label3: 'Авто-воспроизведение GIF',
            label4: 'Комментарии:'
          }
        }
      },
      security: {
        title1: 'Настройки безопасности аккаунта',
        action1: 'ВЫЙТИ',
        '2fa': {
          external: {
            title: '2FA (Внешние сервисы):',
            option1: 'Google Authenticator:',
            option2: 'Authy:'
          },
          internal: {
            title: '2FA (Свой источник):',
            option1: 'Телефон:',
            option2: 'Эл. почта:'
          },
          activity: {
            title: 'История активности:',
            action: 'завершить все сеансы, кроме текущего',
            online: 'онлайн',
            offline: 'оффлайн'
          }
        }
      },
      theme: {
        title1: 'Настройки темы платформы',
        dark: 'Тёмная тема',
        light: 'Светлая тема',
        system: 'Браузерная'
      }
    },
    minecraftProfile: {
      skin: {
        title: 'Скин персонажа в игре',
        upload: 'Выбрать файл',
        reset: 'Сбросить скин'
      },
      vote: {
        title: 'Голосуй',
        subtitle1: 'Получай 2',
        subtitle2: 'за каждый голос',
        earned: 'Вы уже заработали: {0}',
        hint: 'Награда за голосование начисляется автоматически на игровой счёт пользователя'
      }
    },
    donate: {
      title: 'Пополнение баланса',
      exchangeRate: 'Курс',
      form: {
        username: 'Введите Ваш никнейм',
        amount: 'Введите сумму ₽',
        submit: 'Пополнить'
      },
      rules: {
        username: {
          empty: 'Пожалуйста введите никнейм',
          short: 'Слишком короткий никнейм (мин. 3)',
          long: 'Слишком длинный никнейм (макс. 24)',
          malformed1: 'Никнейм должен начинаться с буквы, цифры, - или _',
          malformed2: 'Никнейм содержит недопустимые символы'
        },
        amount: {
          empty: 'Введите сумму',
          tiny: 'Недопустимая сумма',
          huge: 'Слишком большая сумма'
        },
        agree: {
          false: 'Для продолжения необходимо согласие'
        }
      },
      agree: 'Я ознакомился с условиями ',
      agreeLink: 'оферты',
      discountFrom: 'От {0}₽',
      discountBe: '+{0}% в подарок'
    },
    partnership: {
      guest: {
        title1: 'Партнёрская программа Cifrazia',
        action1: 'Оставить заявку',
        action2: 'Заявка на рассмотрении',
        label1: 'Приветствуем! У тебя есть аудитория? Сотрудничай с нами и зарабатывай!',
        label2: 'Всё, что нужно от Вас - оставить ссылку и рассказать о промокоде. ' +
          'Взамен вы получите до 30% дохода с привлечённой Вами аудитории в наш проект!' +
          '<br><br>Для того чтобы стать партнером Cifrazia выполните простые шаги, указанные ниже:',
        steps: {
          '1': 'Оставьте заявку',
          '2': 'После одобрения, Вам откроется доступ к кабинету партнера',
          '3': 'Сообщайте о нас в медиа, распространяя ссылки и промокоды (Lp, Пре-роллы и т.д.)',
          '4': 'Выводите до 30% Доната в личном кабинете'
        }
      },
      user: {
        title1: 'Кабинет партнёра {0}',
        balance: 'Баланс: {0} руб.',
        action1: 'Вывести средства',
        title2: 'Ваш доход составляет',
        // action1Tooltip: 'Для вывода средств ваш баланс должен содержать минимум 10₽',
        action1Tooltip: 'Не реализовано. Пожалуйста обратитесь в тех. поддержку.',
        title3: 'Статистика',
        title4: 'Последние 50 транзакций',
        links: {
          title1: 'Реферальная ссылка',
          label1: 'Переходов:',
          title2: 'Промокод',
          label2: 'Регистраций:'
        },
        progress: {
          hint: 'Ваш процент растет от регистраций по Вашему промокоду и пополнения баланса привлеченными пользователями.' +
            '<br>Достижение точек ниже дает Вам +1% к доходу',
          label1: 'Регистрации по Вашему промокоду:',
          label1_1: '{count} пользователей | {count} пользователь | {count} пользователя | {count} пользователей',
          label2: 'Пополнения баланса привлеченными игроками:',
          label2_1: '{0} руб.'
        },
        stats: {
          label1: 'Переходы',
          label2: 'Регистрации',
          label3: 'Транзакции (кол-во)',
          label4: 'Транзакции (суммы)',
          chartRange: {
            hourly: 'Час',
            daily: 'День',
            monthly: 'Месяц',
            yearly: 'Год'
          }
        }
      }
    },
    documents: {
      title: 'Документы и соглашения'
    }
  },
  titles: {
    index: '%s | Главная',
    signIn: '%s | Авторизация',
    restoreAccess: '%s | Восстановление пароля',
    signUp: '%s | Регистрация',
    profile: '%s | Профиль',
    minecraftProfile: '%s | Minecraft профиль',
    partnership: '%s | Партнёрская программа',
    documents: '%s | Документы и соглашения',
    donate: '%s | Донат'
  },
  toasts: {
    saved: 'Изменения сохранены!',
    copied: 'Скопировано в буфер обмена!',
    copyFailed: 'Не удалось скопировать в буфер обмена.',
    skinLoadError: 'Ошибка загрузки скина'
  },
  messages: {
    authRequired: 'Вы должны быть авторизованы',
    // discordLinked: 'Аккаунт Discord был успешно привязан',
    // discordUnlinked: 'Аккаунт Discord был успешно отвязан',
    signUpSuccess: 'Вы были успешно зарегистрированы!<br>Перенаправляем на главную...',
    restoreAccessSuccess: 'Доступ восстановлен!<br>Перенаправляем на главную...',
    partnershipApplication: {
      success: {
        title: 'Спасибо за Вашу заявку!',
        text: 'Ваша заявка принята на рассмотрение. После ее обработки, с Вами свяжется сотрудник Cifrazia и сообщит о результате обработки и дальнейших действиях '
      }
    }
  },
  errors: {
    'Ability not found': 'Способность не доступна',
    'Already authorized': 'Уже авторизован',
    'Can not purchase item with this currency': 'Покупка предмета за данную валюту не возможна',
    'Captcha failed': 'Ошибка CAPTCHA. Вы точно не робот?',
    'Desktop not found': 'Cifrazia Desktop не найден',
    'Either user joined to different server, or user ain`t joining at all': 'Пользователь подключен к другому серверу, или не подключен вовсе',
    'Email already in use': 'E-mail уже используется',
    'Email already taken': 'Email уже используется',
    'Email is disposable': 'Данный Email запрещён',
    'Email is taken': 'E-mail уже используется',
    'Failed to authorize': 'Ошибка авторизации',
    'Failed to send E-mail: Too many requests': 'Ошибка отправки E-mail: Слишком много запросов',
    'Failed to send email': 'Ошибка отправки Email',
    'Group not found': 'Группа не найдена',
    'Image not found': 'Картинка не найдена',
    'Image not provided': 'Изображение не предоставлено',
    'Invalid IP address': 'Неверный IP адрес',
    'Invalid credentials': 'Неверный логин или пароль',
    'Invalid invitation code': 'Недействительный код приглашения',
    'Invalid login': 'Неправильный никнейм / E-mail',
    'Invalid or expired OTP token': 'Неправильный или устаревший код',
    'Invalid password type': 'Неверный тип пароля',
    'Invalid session. Did you change your IP?': 'Неверная сессия. Вы сменили IP?',
    'Invalid session. Unable to connect to Cifrazia Desktop': 'Неверная сессия. Невозможно подключится к Cifrazia Desktop',
    'Invalid skin ratio': 'Неправильные пропорции скина',
    'Invalid skin ratio. Must be 2:1 or 1:1': 'Соотношение сторон скина должно быть 2:1 или 1:1',
    'Invalid skin size': 'Неправильный размер скина',
    'Invalid token': 'Неверный токен',
    'Invalid user uuid': 'Неверный UUID пользователя',
    'Invalid username': 'Недействительный никнейм',
    'Method not found': 'Метод не найден',
    'Modpack not found': 'Модпак не найден',
    'Not authorized': 'Не авторизован',
    'Not encrypted': 'Не зашифровано',
    'Not implemented yet': 'Не реализовано',
    'Only partners can access this action': 'Данное действие допустимо только партнёрам',
    "Password doesn't match": 'Пароль не совпадает',
    'Permission not available': 'Право не доступно',
    'Permission not found': 'Право не найдено',
    'Please, check your email inbox and provide us security code': 'Пожалуйста, введите код, отправленный на Ваш Email',
    'Please, provide one-time code from your Mobile app': 'Пожалуйста, введите код из мобильного приложения двух-факторной аутентификации',
    'Requested kit can not be bought': 'Невозможно купить запрошенный набор',
    'Requested kit is not available for you': 'Запрошенный набор Вам не доступен',
    'Secured user can not be punished': 'Защищённый пользователь не может быть наказан',
    'Server not found': 'Сервер не найден',
    'Skin size exceeds 3MB': 'Размер файла превышает 3МБ',
    'SkinSizeLarge': 'Размер скина превышает 3MB',
    'Something went wrong': 'Что-то полшло не так',
    'Token is invalid or expired': 'Токен недействителен или просрочен',
    'Too many requests at same time': 'Слишком много запросов',
    'Two-Step authentication already enabled for this account': 'Двух-факторная аутентификация уже подключена к этому аккаунту',
    'Two-Step authentication is not enabled for this account': 'Двух-факторная аутентификация не подключена к этому аккаунту',
    'UUID does not match the token': 'UUID не соответствует токену',
    'Unsupported data type': 'Недопустимый тип данных',
    'User can not have negative balance': 'Пользователь не может иметь отрицательный баланс',
    'User not found': 'Пользователь не найден',
    'Username already in use': 'Никнейм уже используется',
    'Username already taken': 'Нейнейм уже используется',
    'Username is taken': 'Никнейм уже используется',
    'Welcome back! Do you want to restore access?': 'Приветствуем снова! Вы хотите восстановить доступ?',
    'You can not invite yourself': 'Вы не можете приглашать себя',
    'You can not upload HD skins': 'Вы не можете загружать HD скины',
    'You have already applied to our program': 'Вы уже подали заявку на нашу программу',
    'agree_tos': 'Подтвердите согласие с <a href="/documents/tos" target="_blank">Условиями Пользования</a>',
    'email': 'Недопустимый E-mail',
    'invalidInvitation': 'Код приглашения недействителен',
    'username': 'Недопустимый Никнейм'
  },
  direct: {
    'Asia': 'Азия',
    'Upwards': 'Наверх',
    'Transactions': 'Транзакции',
    'Total sum': 'Общая сумма',
    'Average check': 'Средний чек',
    'Sign ups': 'Регистрации',
    'Transitions': 'Переходы',
    'soon': 'скоро',
    months: {
      '0': 'Январь',
      '1': 'Феваль',
      '2': 'Март',
      '3': 'Апрель',
      '4': 'Май',
      '5': 'Июнь',
      '6': 'Июль',
      '7': 'Август',
      '8': 'Сентябрь',
      '9': 'Октябрь',
      '10': 'Ноябрь',
      '11': 'Декабрь'
    },
    bytes: {
      'B': 'Б',
      'KB': 'КБ',
      'MB': 'МБ',
      'GB': 'ГБ',
      'TB': 'ТБ',
      'PB': 'ПБ',
      'EB': 'ЕБ',
      'b': 'б',
      'Kb': 'Кб',
      'Mb': 'Мб',
      'Gb': 'Гб',
      'Tb': 'Тб',
      'Pb': 'Пб',
      'Eb': 'Еб'
    }
  },
  files: {
    confidentiality,
    data_handle,
    eula,
    international_payments,
    itemStorage,
    minecraft_rules,
    payments,
    requisites,
    return_policy,
    support,
    tos
  },
  documents: {
    confidentiality: 'Политика конфиденциальности',
    data_handle: 'Обработка персональных данных',
    eula: 'Пользовательское соглашение',
    international_payments: 'Политика информационной безопасности',
    minecraft_rules: 'Правила сервисов Minecraft',
    payments: 'Платежи',
    requisites: 'Реквизиты',
    return_policy: 'Политика возврата',
    support: 'Нужна помощь?',
    itemStorage: 'Хранилище предметов',
    tos: 'Условия использования'
  },
  header: {
    buttons: {
      signIn: 'ВОЙТИ',
      download: 'СКАЧАТЬ'
    },
    nav: {
      servers: 'Сервера',
      donate: 'Донат',
      skin: 'Скин',
      votes: 'Голоса',
      help: 'Помощь',
      discord: 'Discord'
    }
  },
  footer: {
    site: {
      title: 'Сайт',
      home: 'Главная',
      news: 'Новости',
      modpacks: 'Сборки',
      market: 'Магазин',
      adminApply: 'Подать заявку',
      vote: 'Голосовать'
    },
    company: {
      title: 'Компания',
      partnership: 'Партнёрская программа'
    },
    documents: {
      title: 'Документы и соглашения'
    },
    support: {
      title: 'Поддержка',
      sitemap: 'Карта сайта',
      feedback: 'Обратная связь',
      status: 'Состояние платформы',
      blog: 'Блог разработчиков',
      api: 'API'
    },
    hint: 'Мы предоставляем ознакомительный бесплатный вариант игры Minecraft',
    buy1: 'купить лицензионную версию игры можно',
    buy2: 'здесь'
  }
}
