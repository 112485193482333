import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from './index'
import { ModalsState } from '~/types/modals'
import { updatePosition } from '~/utils/misc'
import { StrDict } from '~/types'

// noinspection JSUnusedGlobalSymbols
export const state: () => ModalsState = () => ({
  show: null,
  data: {}
})

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<ModalsState, RootState> = {
  visible: s => !!s.show,
  name: s => s.show,
  data: s => s.data
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<ModalsState, RootState> = {
  show ({ commit }, params: { name: string, data: StrDict | null }) {
    commit('show', params)
    updatePosition()
  },
  hide ({ commit }) {
    commit('hide')
  }
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<ModalsState> = {
  show (state, params: { name: string, data: StrDict | null }) {
    console.debug(`Mutate: ${params.name} ${params.data}`)
    state.data = params.data || null
    state.show = params.name
  },
  hide (state) {
    console.debug(`Mutate: ${state.show} ${null}`)
    state.show = null
  }
}
