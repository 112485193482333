export default `Настоящие Правила поведения Пользователя регулируют действия и взаимоотношения Пользователей во время Игры, общения на форуме, в чате, а также в группах в социальных сетях CIFRAZIA.COM. (далее – Правила).

1 ОБЩИЕ ПОЛОЖЕНИЯ
1.1 Прежде чем начать использовать Интернет-ресурс, а также вступать в группы ЦИФРАЗИИ в социальных сетях, каждый Пользователь обязан ознакомиться с настоящими Правилами.
1.2 Пользователь не освобождается от обязательств, предусмотренных настоящими Правилами, если Пользователь не ознакомился с ними.

2 ПРАВИЛА ПОВЕДЕНИЯ ПОЛЬЗОВАТЕЛЯ ПРИ СОЗДАНИИ АККАУНТА НА ИНТЕРНЕТ-РЕСУРСЕ
2.1 При регистрации на Интернет-ресурсе Пользователь обязан указать действующий электронный адрес, на который придет письмо со ссылкой о подтверждении регистрации и активации Аккаунта.
2.2 Пользователь может иметь только 1 (один) Аккаунт.
2.3 Передача и продажа Аккаунта другим Пользователям или третьим лицам запрещена. За нарушение данного пункта все Аккаунты будут заблокированы.
2.4 Имя (никнейм), выбранное Пользователем для регистрации, не должно:
2.4.1 противоречить законам РФ;
2.4.2 нарушать авторские и смежные права третьих лиц;
2.4.3 быть пошлым и/или оскорбительным, содержать ненормативную лексику, нарушать нормы морали;
2.4.4 быть схожим на никнеймами Администраторов и Модераторов форума, а также содержать в себе слова «admin», «administrator», «moder», «moderator»,.
При нарушении данного правила – блокировка Аккаунта.
2.5 Аватар Пользователя не должен:
2.5.1 противоречить законам РФ;
2.5.2 нарушать авторские и смежные права третьих лиц;
2.5.3 содержать названия и/или изображения торговых марок и логотипов третьих лиц, а также доменных имен как третьих лиц, так зарегистрированных на имя Пользователя;
2.5.4 содержать рекламу другого проекта, нацистскую символику, изображения, унижающие честь и человеческое достоинсво, изображения, разжигающие межнациональную рознь, содержащие пропаганду алкоголя и табака;
2.5.5 иметь эротический или порнографический характер;
2.5.6 быть пошлым и/или оскорбительным, содержать ненормативную лексику, нарушать нормы морали;
2.5.7 быть схожим с Аватарами Администраторов и Модераторов форума.
При нарушении данного правила – блокировка Аккаунта.

3 ПРАВИЛА ПОВЕДЕНИЯ ПОЛЬЗОВАТЕЛЯ ПРИ ОБЩЕНИИ В ГРУППАХ В СОЦИАЛЬНЫХ СЕТЯХ ЦИФРАЗИИ, НА ФОРУМЕ И В ЧАТЕ ИНТЕРНЕТ-РЕСУРСА
3.1 Пользователь несет полную ответственность за всю информацию, которую он публикует на форуме, чате и в группах ЦИФРАЗИИ в социальных сетях.
3.2 Создание тем допускается только в соответствующих тематических разделах форума/группы. Пользователь обязан убедиться, что подобная тема отсутствует, воспользовавшись «Поиском» по форуму/группе. Пользователю запрещено создавать одинаковые темы, а также дублировать темы в разные разделы форума/группы.
3.3 Название темы должно содержать ее суть. Написание темы заглавными буквами и/или добавление подряд более двух символов, а исключением аббревиатур – запрещено
3.4 При написании сообщений ПОЛЬЗОВАТЕЛЮ ЗАПРЕЩЕНО:
3.4.1 отправлять сообщения, содержащие непристойные, оскорбительные, вульгарные выражения, а также выражения, содержащие угрозы, разжигающие религиозную, расовую, межнациональную вражду и/или нарушающие законодательство РФ.
Наказание: при наличии жалобы - блокировка Аккаунта на 30 (тридцать) дней / перманентная блокировка Аккаунта.
3.4.2 использовать ненормативную лексику, завуалированную ненормативную лексику и жаргонные выражения.
Наказание: удаление с сервера / блокировка доступа к чату и/или форуму на 24 (двадцать четыре) часа / блокировка Аккаунта на 7 (семь) суток.
3.4.3 флудить – оставлять сообщения не имеющие смысловой нагрузки, оффтопить – оставлять сообщения не соответствующие теме обсуждения и уводящие обсуждение в другую тему, отправлять не более 2-х схожих по смыслу сообщений в 5 (пять) минут , отправлять сообщения не несущие какой-либо информационной нагрузки.
Наказание: удаление с сервера / блокировка доступа к чату на 5 (пять) часов / блокировка Аккаунта на 3 (три) дня.
3.4.4 создавать темы с обсуждением действий ЦИФРАЗИИ, Администраторов и Модераторов.
Наказание: удаление Аккаунта / Блокировка доступа к чату и/или форуму / Блокировка Аккаунта на 2 (два) дня.
3.4.5 оскорблять ЦИФРАЗИЮ, а также Администраторов и Модераторов.
Наказание: блокировка Аккаунта на 7 (семь) дней.
3.4.6 критиковать других Пользователей и провоцировать конфликты;
Наказание: удаление с сервера / блокировка доступа к чату на 5 (пять) часов / блокировка Аккаунта на 3 (три) дня.
3.4.7 оставлять рекламные сообщения, а также сообщения, которые содержат ссылки на другие сайты, включая ссылки на социальные сети.
Наказание: Ограничение доступа к чату на 3 (три) дня / Перманентная блокировка Аккаунта.
3.4.8 использовать, распространять вредоносное, а как же любое иное программное обеспечение, трейнеры (то есть программы, предназначенные для изменения игровых параметров), чит-коды (то есть коды, которые могут быть введены в Игру, с целью изменения ее хода), макросы (программное обеспечение, позволяющее автоматизировать действия в Игре), а также распространять информацию о них.
Наказание: Блокировка аккаунта на 1 (один) год / Перманентная блокировка Аккаунта, а также блокировка Аккаунта по IP адресу.
3.4.9 вводить в заблуждение Администраторов и Модераторов по техническим и игровым вопросам, а также оставлять сообщения в чате касательно дюпов. Например, «Умею дюпать», «Научи дюпать», «Как тебе читы» и тому подобное, даже в шуточной форме.
Наказание: Блокировка доступа к чату на 10 (десять) часов / Блокировка Аккаунта на 5 (пять) дней / Перманентная блокировка Аккаунта на Интернет-ресурсе и в Игре.
3.4.10 использовать и распространять среди Пользователей информацию об ошибках (багах) в Игре и другом программном обеспечении Интернет-ресурса. При обнаружении ошибки (бага) Пользователь обязан сообщить о ней Администратору.
Наказание: Блокировка Аккаунта 1 год / Перманентная блокировка Аккаунта в Игре и на Интернет-ресурсе.
3.4.11 создавать сообщения с нечитабельным шрифтом и/или цветом, и/или писать хаотично заглавными буквами (например: «ПрИвЕт»), и/или использовать большой размер шрифта; злоупотреблять смайлами (более 5-ти подряд).
Наказание: удаление с сервера/блокировка доступа к чату и/или форуму на 2 (два) часа / блокировка Аккаунта на 3 (три) дня.
3.4.12 игнорировать просьбы и предупреждения Администраторов и Модераторов;
Наказание:Блокировка доступа к чату на 10 (десять) часов / Блокировка Аккаунта на 5 (пять) дней / Перманентная блокировка Аккаунта на Интернет-ресурсе и в Игре.
3.4.13 разглашать личную информацию других Пользователей и третьих лиц;
Наказание:удаление с сервера/блокировка доступа к чату и/или форуму на 2 (два) часа / блокировка Аккаунта на 3 (три) дня.
3.4.14 попрошайничать у Администраторов и Модераторов;
Наказание: удаление с сервера/блокировка доступа к чату и/или форуму на 5 (пять) часов / блокировка Аккаунта на 2 (два) дня.
3.4.15 мешать проведению мероприятий и конкурсов ЦИФРАЗИИ.
Наказание: удаление с сервера / Блокировка Аккаунта от 2 (двух) часов и более на усмотрение Администратора.
3.4.16 нарушать законодательство РФ.
Управляющий вправе устанавливать иную ответственность Пользователя за нарушение данного пункта.
3.5 Пользователю рекомендуется:
3.5.1 доброжелательно и уважительно относиться к другим Пользователем, Администраторам и Модераторам;
3.5.2 размещать любую полезную информацию, связанную с тематикой Интернет-ресурса, чата, форума и групп в социальных сетях;
3.5.3 просить помощи, совета и оставлять вопросы в соответствующих разделах форума и группах в социальных сетях

4 ПРАВА И ОБЯЗАННОСТИ АДМИНИСТРАТОРОВ И МОДЕРАТОРОВ НА ИНТЕРНЕТ-РЕСУРСЕ И ГРУППАХ В СОЦИАЛЬНЫХ СЕТЯХ
4.1 Действия Администратора не обсуждаются, за исключением решений младшей Администрации и может быть пересмотрено только Управляющими.
4.2 Администраторы и Модераторы следят за соблюдением Пользователями Правил на форуме и чате Интернет-ресурса и в группах ЦИФРАЗИИ в социальных сетях.
4.3 Администраторы и Модераторы обязаны соблюдать цензуру и речевые нормы, быть общительными, грамотными, воспитанными и толерантными по отношению ко всем Пользователям. (Они не обязаны общаться на «Вы» и не обязаны откликаться на «Эй, иди сюда»).
4.4 Администраторы и Модераторы обязаны соблюдать настоящие Правила
4.5 Администраторы и Модераторы не обязаны обосновывать свои действия Пользователям
4.6 Администраторы и Модераторы следят за тем, чтобы общение Пользователей на форуме, чате, в группах ЦИФРАЗИИ в социальных сетях было максимально комфортным и уважительным.
4.7 Администраторы и Модераторы вправе принимать самостоятельные решения
4.8 Администраторы и модераторы обязаны предупреждать Пользователя о нарушениях настоящих Правил и выносить Пользователям наказания.
4.9 Администраторы и модераторы вправе удалять сообщения, на которые п.3.5. настоящих Правил, установлен запрет
4.10 Администраторы вправе блокировать, удалять Аккаунт Пользователя

5 ПРАВИЛА ПОВЕДЕНИЯ ПОЛЬЗОВАТЕЛЕЙ В ПРОЦЕССЕ ИГРЫ
5.1 Пользователю в ходе игрового процесса запрещено:
5.1.1 Использовать автоматизированные скрипты для сбора информации или другого рода взаимодействия с Игрой.
5.1.2 Размещать в Игре объекты интеллектуальной собственности без согласия их правообладателей.
5.1.3 Размещать в Игре личную информацию третьих лиц без их согласия, в том числе домашние адреса, телефоны и паспортные данные.
5.1.4 Размещать в Игре рекламу, коммерческие предложения, агитационную и любую другую навязчивую информацию, кроме случаев, разрешенных ЦИФРАЗИЕЙ.
5.1.5 Размещать в Игре материалы, оскорбляющие или унижающие честь и достоинство других Пользователей либо третьих лиц, а также ссылки на такие материалы.
5.1.6 Использовать в Игре нецензурные, бранные и оскорбительные слова и словосочетания.
5.1.7 Размещать вредоносные программы или ссылки на ресурсы, которые содержат или могут содержать такие программы, а также распространять информацию о стороннем программном обеспечении, трейнерах, чит-кодах, макросах.
5.1.8 Размещать в Игре материалы порнографического или эротического характера, а также ссылки на них.
5.1.9 Размещать в Игре угрозы, а также призывы к насилию и совершению иных противоправных действий.
5.1.10 Размещать в Игре материалы с элементами насилия, жестокости, расовой, межнациональной или межрелигиозной розни, а также ссылки на такие материалы.
5.1.11 Пропагандировать и демонстрировать в Игре нацистскую, экстремистскую или иную приравненную к ним атрибутику, символику и иные объекты.
5.1.12 Пропагандировать в Игре преступную и иную противоправную деятельность, а также размещать руководства по совершению противоправных действий.
5.1.13 Размещать в Игре любую другую информацию, которая, по мнению ЦИФРАЗИИ, нарушает законодательство, нормы морали и этики или является нежелательной.
5.1.14 Совершать преступные и иные противоправные действия.
Наказание Пользователя за несоблюдение пп.5.1.1. – 5.1.14 настоящих Правил: Блокировка аккаунта на 1 (один) год / Перманентная блокировка аккаунта, а также блокировка Аккаунта по IP адресу.
5.2 При использовании в процессе Игры предмета «Таблички» Пользователь не должен:
5.2.1 нарушать законы РФ;
5.2.2 нарушать авторские и смежные права третьих лиц;
5.2.3 использовать названия торговых марок третьих лиц, а также доменных имен как третьих лиц, так зарегистрированных на имя Пользователя;
5.2.4 быть пошлым и/или оскорбительным, содержать ненормативную лексику, нарушать нормы морали.
Наказание: блокировка Аккаунта.
5.3 Пользователь принимает и соглашается с тем, что ЦИФРАЗИЯ не возвращает Пользователю утраченные им предметы, полученные им за пожертвования (донаты) и во время игрового процесса.
5.4 ЦИФРАЗИЯ вправе изъять предмет, регион в Игре, точку варпа (точка в игровом мире Пользователя), а также ограничить доступ к региону и/или точке варпа.
5.5 Пользователь самостоятельно несет ответственность за действия другого Пользователя, которого он добавил в свой регион.
5.6 Пользователю запрещено каким-либо образом использовать (демонстрировать, раздавать, привлекать внимание и подобное) предметы в Игре, добытые нечестным путем (дюп).
Наказание: Блокировка аккаунта 1 год, а также реген территории с дюпом (восстановление первоначального состояния) / Перманентная блокировка Аккаунта в Игре и на Интернет-ресурсе.
5.7 Пользователю запрещено гиферство, а именно игровой вандализм, нанесение морального или материального ущерба другим Пользователям в Игре.
Наказание: Блокировка Аккаунта 7 суток / Перманентная блокировка Аккаунта в Игре и на Интернет-ресурсе.
5.8 Запрещено намеренное выталкивание / затаскивание / заманивание Пользователей Игры под любым предлогом на территорию региона с включённым PVP. Исключение составляет прямое предложения PVP-арены – места на сервере в Игре, где происходят виртуальные бои Персонажей Пользователей Игры. Пользователю запрещено приносить на PVP-арены какие-либо предметы, способные вытолкнуть Персонажа другого Пользователя Игры в опасную зону (зону, где Персонажу Пользователя могут нанести урон). Регион с включенным флагом PVP должен содержать перед всеми входами на него на видном месте таблички, сообщающие о включенном PVP.
Наказание: Блокировка Аккаунта 5 (пять) дней.
5.9 Запрещено ставить точку дома спавна (точка появления / телепортации Персонажа Пользователя в Игре) (sethome /home set /warp) в следующих случаях:
5.9.1 если около этой точки находится жидкий блок Игры - лава;
5.9.2 если под этой точкой высота более 3-х блоков – кубов, из которых состоит мир Игры;
5.9.3 если около этой точки находятся ловушки, которые способны убить Персонажа Пользователя в Игре;
5.9.4 если на/возле этой точки является активным PvP-режим;
5.9.5 если в данной области находится чужой регион;
5.9.6 если в данной области находится чужая ферма мобов (живых движущихся объектов в Игре);
5.9.7 в дополнительных мирах Игры.
Наказание: блокировка Аккаунта 3 (три) дня и удаление точки дома спавна.
5.10 Запрещена частая реклама публичных мест в Игре (варпов, магазинов, PVP-арен). Пользователю разрешено упоминание о них не чаще 1 (одного) раза в 5 (пять) минут.
Наказание: удаление с сервера / Блокировка доступа к чату 30 (тридцать) минут / Блокировка Аккаунта 2 (два) дня.
5.11 Запрещено придавать Персонажу неподобающий облик (скины и/или плащи) и не соответствующий нравственным нормам.
Наказание: удаление с сервера с обязательством придать новый облик Персонажу / Блокировка Аккаунта 20 (двадцать) минут.
5.12 Пользователю запрещено нарушать работу сервера Игры в любом виде.
Наказание: Блокировка аккаунта 5 дней / Перманентная блокировка Аккаунта и реген территории (восстановление первоначального состояния).
5.13 Пользователь вправе производить обмен ресурсов с другими Пользователями. Если какой-то из Пользователей нарушил условия обмена, то он несет ответственность в случае, если имеются неоспоримые доказательства его нарушения (например, скриншоты).
Наказание: Блокировка Аккаунта 3 (три) дня.
Пользователь принимает и соглашается, что ЦИФРАЗИЯ не возвращает и не компенсирует никаким способом Пользователю не полученные ресурсы от другого Пользователя в результате нечестного обмена.
5.14 Персонажу Пользователя в Игре запрещено находиться в регионе Персонажа другого Пользователя, в приватных строениях, если этого не хочет хотя бы один Пользователь данного региона. Исключение – Администраторы и Модераторы.
Наказание: При наличии жалобы, в которой зафиксировано время (команда ontime) - Удаление с сервера / Блокировка Аккаунта 2 (два) дня.
5.15 Пользователю запрещено раздавать ресурсы, полученные нечестным путем.
Наказание: Изъятие ресурсов у всех Пользователей такой раздачи / Возможна блокировка Аккаунта от 3 (трех) до 30 (тридцати) дней по усмотрению Администратора.
5.16 Пользователю запрещено осуществлять покупку и продажу чего-либо за ресурсы, которые не относятся к Игре, например, валюта любых государств, ключи от иных игр, объекты/предметы иных игр, внутриигровой валюты, в том числе и пытаться продать/купить.
Наказание: Блокировка Аккаунта до 1 месяца / Перманентная блокировка Аккаунта.
5.17 Пользователю запрещено подделывать доказательства в любом виде.
Наказание: Блокировка Аккаунта 7 (семь) дней / Перманентная блокировка Аккаунта.
5.18 Пользователю запрещено мешать Пользователям, которые производят видеосъемку игрового процесса для канала на youtube.com. Рядом с никнеймами таких Пользователей имеется отметка «youtube».
Наказание: Блокировка Аккаунта 30 (тридцать) дней / Перманентная блокировка аккаунта.
5.19 При строительстве в Игре Пользователю запрещено:
5.19.1 портить карту (поверхность мира Игры) любыми способами, например, возводить столбы, коробки, различные формы построек 1х1х3 блоков, делать надпись цифр «135» и подобное.
Наказание: Блокировка Аккаунта на 10 (десять) часов и удаление постройки / региона.
5.19.2 Пользователь, имеющий в Игре точку спавна (точку появления Персонажа в Игре), в течение 7 (семи) дней, обязан построить красиво оформленную игровую общественную постройку (например, магазин, кафе, казино и подобное).
Наказание: Предупреждение и через 72 часа и удаление территории.
5.19.3 создавать общественные качалки, а именно автоматическое выкачивание ресурсов на территории без использования Персонажа Пользователя. Пользователю разрешено создание частной качалки.
Наказание: Блокировка аккаунта 7 (семь) дней.
5.19.4 строить магазин на воде или в воздухе.
Наказание: Предупреждение с просьбой перестроить магазин / Удаление магазина и варпа.
5.20 Администрация праве заблокировать Аккаунт Пользователя за излишнюю придирчивость последнего к настоящим Правилам.
5.21 Запрещено строительство механизмов, позволяющие обходить AFK режим, любые способы запрещены
Наказание: Кик с сервера/бан на 3 (три) дня

6 ПРАВА И ОБЯЗАННОСТИ АДМИНИСТРАТОРОВ
6.1 Администраторы обязаны контролировать игровой процесс и вовремя предотвращать различные инциденты, которые могут повлечь за собой нарушение настоящих Правил, а также помогать Пользователям в различных ситуациях.
6.2 Администраторы обязаны указывать причину блокировки и/или сноску на соответствующий пункт Правил, который был нарушен.
6.3 Администраторы обязаны всегда делать скриншот нарушения Пользователя.
6.4 Администраторам запрещено использование игровых полномочий во вред серверу.
6.5 Администраторы не вправе обеспечивать Пользователей ресурсами/предметами для Игры.
6.6 При нахождении кого-либо из Администраторов в регионе Игры, Пользователи которого грубо нарушают правила, такой Администратор лишается должности.
6.7 Администратор не вправе давать поблажки Пользователям-соучастникам региона Игры, в противном случае он лишается должности.
6.8 За неоднократное и/или грубое нарушение настоящих Правил срок и вид наказания может быть изменен по усмотрению Администратора.
6.9 Высшая администрация вправе применять наказания по иным причинам, не указанным в данных правилах.

7 СРОК ДЕЙСТВИЯ ПРАВИЛ
7.1 Настоящие Правила вступают в силу с момента совершения Пользователем действий, указанных в п.2.8. Пользовательского соглашения и действуют неопределенный срок, если Пользовательское соглашение не было расторгнуто по инициативе одной из Сторон.
7.2 Настоящие Правила могут быть изменены ЦИФРАЗИЕЙ в одностороннем порядке, в любое время и без предварительного уведомления Пользователя.`
