import hljs from 'highlight.js'
import { Marked, Slugger } from 'marked'
// @ts-ignore
import { mangle } from 'marked-mangle'
// @ts-ignore
import { markedEmoji } from 'marked-emoji'
// @ts-ignore
import markedLinkifyIt from 'marked-linkify-it'
// @ts-ignore
import converter from 'discord-emoji-converter/dist/discord-emoji-converter.min'
import markedKatex from 'marked-katex-extension-ts'
import katex from 'katex'
import * as _ from 'lodash'
// import mermaid from 'mermaid'
import 'katex/dist/katex.min.css'
import './marked.scss'
import Clipboard from 'clipboard'
import * as process from 'process'

let clipboard: Clipboard

type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export type Options = {
  heading: {
    prefix: string
    link: {
      class: string
      iconURL: string
      icon: {
        style: string
        class: string
      }
      text: string
    }
  }
  code: {
    copy: {
      iconURL: string
      text: string
      icon: {
        style: string
        class: string
      }
      button: {
        style: string
        class: string
      }
    }
  }
}

const defaultOptions: Options = {
  heading: {
    prefix: '',
    link: {
      class: 'md-header-link',
      iconURL: '',
      icon: {
        style: '',
        class: 'mdi mdi-link-variant'
      },
      text: '#'
    }
  },
  code: {
    copy: {
      iconURL: '',
      text: 'copy',
      icon: {
        style: 'font-size: 21px; opacity: 0.4;',
        class: 'mdi mdi-content-copy'
      },
      button: {
        style: 'position: absolute; top: 7.5px; right: 6px; cursor: pointer; outline: none;',
        class: ''
      }
    }
  }
}

function NewMarked (options: DeepPartial<Options>): Marked {
  const marked = new Marked()
  const opt = _.merge(defaultOptions, options) as Options

  function codeClipboard (code: string, raw: string): string {
    const content = raw
      .replaceAll('"', '&quot;')
      .replaceAll("'", '&apos;')
    let icon = 'copy'
    if (opt.code.copy.iconURL) {
      icon = `<img src="${opt.code.copy.iconURL}" alt="copy"/>`
    } else if (opt.code.copy.text) {
      icon = opt.code.copy.text
    } else if (opt.code.copy.icon) {
      icon = `<span style="${opt.code.copy.icon.style}" class="${opt.code.copy.icon.class}"></span>`
    }
    return `<div style="position: relative;">
${code}
<button class="markdown-it-code-copy ${opt.code.copy.button.class}" style="${opt.code.copy.button.style}" data-clipboard-text="${content}">
${icon}
</button>
</div>`
  }

  marked.use({
    async: true,
    breaks: true,
    gfm: true,
    pedantic: false,
    headerIds: false,
    headerPrefix: undefined
  })
  marked.use({
    renderer: {
      heading (text: string, level: 1 | 2 | 3 | 4 | 5 | 6, raw: string, slugger: Slugger): string {
        const l = opt.heading.link
        raw = raw.toLowerCase().trim().replace(/<[!/a-z].*?>/ig, '')
        const slug = opt.heading.prefix + slugger.slug(raw)
        let icon = ''
        let link = ''
        if (opt.heading.link.iconURL) {
          icon = `<img src="${l.iconURL}" alt="${text}"/>`
        } else if (l.text) {
          icon = `${l.text}`
        } else if (l.icon) {
          icon = `<span style="${l.icon.style}" class="${l.icon.class}"></span>`
        }
        if (icon) {
          link = `<a href="#${slug}" class="${l.class}">${icon}</a>`
        }
        return `<h${level} id="${slug}">${link}${text}</h${level}>\n`
      },
      code (code: string, lang?: string) {
        // if (lang === 'mermaid' || code.match(/^sequenceDiagram/) || code.match(/^graph/)) {
        //   return '<pre class="mermaid">' + code + '</pre>'
        // }
        if (lang === 'tex' || lang === 'katex' || lang === 'latex' || lang === 'math') {
          return katex.renderToString(code, { throwOnError: false })
        }
        if (lang && hljs.getLanguage(lang)) {
          try {
            const cls = `hljs language-${lang}`
            const hl = hljs.highlight(code, { language: lang })
            return codeClipboard(`<pre class="${cls}"><code class="${cls}">${hl.value}</code></pre>`, code)
          } catch (__) {
          }
        }
        // try {
        //   const hl = hljs.highlightAuto(code)
        //   const cls = `hljs language-${hl.language}`
        //   return codeClipboard(`<pre class="${cls}"><code class="${cls}">${hl.value}</code></pre>`, code)
        // } catch (__) {
        // }
        return codeClipboard('<pre><code>' + code + '</code></pre>', code)
      }
    }
  })
  marked.use(markedLinkifyIt({}, {}))
  marked.use(markedEmoji({ unicode: true, emojis: converter.emojis }))
  marked.use(mangle())
  marked.use(markedKatex({ throwOnError: false }))
  return marked
}

function onUpdate () {
  if (process.client) {
    clipboard?.destroy()
    clipboard = new Clipboard('.markdown-it-code-copy')
  }
  // await mermaid.init({
  //   darkMode: true,
  //   theme: 'dark'
  // })
}

export {
  NewMarked,
  onUpdate,
  katex,
  // mermaid,
  hljs
}
