import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from './index'
import { LoaderState } from '~/types/loader'

// noinspection JSUnusedGlobalSymbols
export const state: () => LoaderState = () => ({
  status: null
})

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<LoaderState, RootState> = {
  status: s => s.status
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<LoaderState, RootState> = {
  setStatus ({ commit }, status: -1 | 0 | 1 | null) {
    commit('setStatus', status)
  }
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<LoaderState> = {
  setStatus (state, status: -1 | 0 | 1 | null) {
    state.status = status
  }
}
