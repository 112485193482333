import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4c92363e = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _e9bed37e = () => interopDefault(import('../pages/documents/index.vue' /* webpackChunkName: "pages/documents/index" */))
const _a6afd656 = () => interopDefault(import('../pages/documents/confidentiality.vue' /* webpackChunkName: "pages/documents/confidentiality" */))
const _2282c9e8 = () => interopDefault(import('../pages/documents/data_handle.vue' /* webpackChunkName: "pages/documents/data_handle" */))
const _0ddbfb86 = () => interopDefault(import('../pages/documents/eula.vue' /* webpackChunkName: "pages/documents/eula" */))
const _21f6ca5f = () => interopDefault(import('../pages/documents/international_payments.vue' /* webpackChunkName: "pages/documents/international_payments" */))
const _62e4c917 = () => interopDefault(import('../pages/documents/itemStorage.vue' /* webpackChunkName: "pages/documents/itemStorage" */))
const _0dda09d8 = () => interopDefault(import('../pages/documents/minecraft_rules.vue' /* webpackChunkName: "pages/documents/minecraft_rules" */))
const _37d0cc0e = () => interopDefault(import('../pages/documents/payments.vue' /* webpackChunkName: "pages/documents/payments" */))
const _4073a1bb = () => interopDefault(import('../pages/documents/requisites.vue' /* webpackChunkName: "pages/documents/requisites" */))
const _07c9d3e0 = () => interopDefault(import('../pages/documents/return_policy.vue' /* webpackChunkName: "pages/documents/return_policy" */))
const _7446e604 = () => interopDefault(import('../pages/documents/support.vue' /* webpackChunkName: "pages/documents/support" */))
const _d0fa914e = () => interopDefault(import('../pages/donate.vue' /* webpackChunkName: "pages/donate" */))
const _4d5be7a4 = () => interopDefault(import('../pages/guides/index.vue' /* webpackChunkName: "pages/guides/index" */))
const _9a6be34c = () => interopDefault(import('../pages/lootboxes/index.vue' /* webpackChunkName: "pages/lootboxes/index" */))
const _1235ae8f = () => interopDefault(import('../pages/minecraft-profile.vue' /* webpackChunkName: "pages/minecraft-profile" */))
const _08b05208 = () => interopDefault(import('../pages/our-projects/index.vue' /* webpackChunkName: "pages/our-projects/index" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _eb005060 = () => interopDefault(import('../pages/profile/security.vue' /* webpackChunkName: "pages/profile/security" */))
const _2e6efb4c = () => interopDefault(import('../pages/profile/_theme.vue' /* webpackChunkName: "pages/profile/_theme" */))
const _d49a7b26 = () => interopDefault(import('../pages/restore-access.vue' /* webpackChunkName: "pages/restore-access" */))
const _7a3dd228 = () => interopDefault(import('../pages/restore-access-check.vue' /* webpackChunkName: "pages/restore-access-check" */))
const _07bdb5e0 = () => interopDefault(import('../pages/restore-access-confirm.vue' /* webpackChunkName: "pages/restore-access-confirm" */))
const _211f388a = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _0406ba31 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _4ba5465c = () => interopDefault(import('../pages/guides/_slug.vue' /* webpackChunkName: "pages/guides/_slug" */))
const _9dd925dc = () => interopDefault(import('../pages/lootboxes/_slug.vue' /* webpackChunkName: "pages/lootboxes/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    component: _2dfb1658,
    name: "index___ru"
  }, {
    path: "/ru/documents",
    component: _4c92363e,
    children: [{
      path: "",
      component: _e9bed37e,
      name: "documents___ru"
    }, {
      path: "confidentiality",
      component: _a6afd656,
      name: "documents-confidentiality___ru"
    }, {
      path: "data_handle",
      component: _2282c9e8,
      name: "documents-data_handle___ru"
    }, {
      path: "eula",
      component: _0ddbfb86,
      name: "documents-eula___ru"
    }, {
      path: "international_payments",
      component: _21f6ca5f,
      name: "documents-international_payments___ru"
    }, {
      path: "itemStorage",
      component: _62e4c917,
      name: "documents-itemStorage___ru"
    }, {
      path: "minecraft_rules",
      component: _0dda09d8,
      name: "documents-minecraft_rules___ru"
    }, {
      path: "payments",
      component: _37d0cc0e,
      name: "documents-payments___ru"
    }, {
      path: "requisites",
      component: _4073a1bb,
      name: "documents-requisites___ru"
    }, {
      path: "return_policy",
      component: _07c9d3e0,
      name: "documents-return_policy___ru"
    }, {
      path: "support",
      component: _7446e604,
      name: "documents-support___ru"
    }]
  }, {
    path: "/ru/donate",
    component: _d0fa914e,
    name: "donate___ru"
  }, {
    path: "/ru/guides",
    component: _4d5be7a4,
    name: "guides___ru"
  }, {
    path: "/ru/lootboxes",
    component: _9a6be34c,
    name: "lootboxes___ru"
  }, {
    path: "/ru/minecraft-profile",
    component: _1235ae8f,
    name: "minecraft-profile___ru"
  }, {
    path: "/ru/our-projects",
    component: _08b05208,
    name: "our-projects___ru"
  }, {
    path: "/ru/profile",
    component: _d5f21fa2,
    children: [{
      path: "",
      component: _4bb7511c,
      name: "profile___ru"
    }, {
      path: "security",
      component: _eb005060,
      name: "profile-security___ru"
    }, {
      path: ":theme",
      component: _2e6efb4c,
      name: "profile-theme___ru"
    }]
  }, {
    path: "/ru/restore-access",
    component: _d49a7b26,
    name: "restore-access___ru"
  }, {
    path: "/ru/restore-access-check",
    component: _7a3dd228,
    name: "restore-access-check___ru"
  }, {
    path: "/ru/restore-access-confirm",
    component: _07bdb5e0,
    name: "restore-access-confirm___ru"
  }, {
    path: "/ru/sign-in",
    component: _211f388a,
    name: "sign-in___ru"
  }, {
    path: "/ru/sign-up",
    component: _0406ba31,
    name: "sign-up___ru"
  }, {
    path: "/ru/guides/:slug",
    component: _4ba5465c,
    name: "guides-slug___ru"
  }, {
    path: "/ru/lootboxes/:slug",
    component: _9dd925dc,
    name: "lootboxes-slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
