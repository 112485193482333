export default `В соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» настоящим я полностью и безоговорочно принимаю политику конфиденциальности, даю обществу ЦИФРАЗИЯ , согласие на автоматизированную, а также без использования средств автоматизации обработку путем сбора, систематизации, накопления, хранения, уточнения и удаления, а также путем передачи (предоставления) третьим лицам, привлеченных обществом ЦИФРАЗИЯ, моих персональных данных:
- имени и фамилии;
- адрес электронной почты;
указанных мною на сайте <a href="https://cifrazia.com">cifrazia.com</a> , для следующих целей:
- писем с целью обратной связи со мной;
- оказания услуг, предусмотренных сайтом;
- повышения качества оказываемых услуг, качества работы сайта <a href="https://cifrazia.com">cifrazia.com</a> ;
- обработки обращений;
- получения программного обеспечения, информации, и иных материалов от <a href="https://cifrazia.com">cifrazia.com</a> .
Настоящее согласие действует до даты отзыва согласия на обработку персональных связи, для этого необходимо написать письмо на адрес <a href="mailto:help@cifrazia.com">help@cifrazia.com</a>.`
