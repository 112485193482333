import { ActionTree, GetterTree, MutationTree } from 'vuex/types'
import { RootState } from './index'
import {
  BannerNewsItem,
  CoreState,
  DownloadOptions,
  Modpack,
  ModpackStats,
  Monitoring,
  Payment,
  Theme
} from '~/types/core'
import { MCUser } from '~/types/user'

const monitoring: Monitoring = {
  max_online: 0,
  current_online: 0,
  signed_up: 0
}

// noinspection JSUnusedGlobalSymbols
export const state: () => CoreState = () => ({
  mcUser: {},
  downloadOptions: {},
  monitoring,
  modpackStats: {},
  modpacks: [],
  bannerNews: [],
  fetched: false,
  timeZone: 'UTC',
  theme: {
    dark: true,
    mode: 'dark',
    osSupport: false,
    osDark: false,
    default: true
  },
  payment: {
    key: '',
    foreign_key: '',
    freekassa_id: '',
    discounts: []
  }
})

// noinspection JSUnusedGlobalSymbols
export const getters: GetterTree<CoreState, RootState> = {
  mcUser: s => s.mcUser,
  downloadOptions: s => s.downloadOptions,
  monitoring: s => s.monitoring,
  modpackStats: s => s.modpackStats,
  modpacks: s => s.modpacks,
  payment: s => s.payment,
  bannerNews: s => s.bannerNews,
  fetched: s => s.fetched,
  timeZone: s => s.timeZone,
  theme: s => s.theme
}

// noinspection JSUnusedGlobalSymbols
export const actions: ActionTree<CoreState, RootState> = {
  async fetchMinecraftUser ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/user')
      commit('setMinecraftUser', res.data)
    } catch (e: any) {
    }
  },
  async downloadOptionsUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/me/desktop/latest')
      commit('setDownloadOptions', res.data)
    } catch (e: any) {
    }
  },
  async monitoringUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/stats')
      commit('setMonitoring', res.data)
    } catch (e: any) {
    }
  },
  async modpackStatsUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/minecraft/modpacks/stats')
      commit('setModpackStats', res.data)
    } catch (e: any) {
    }
  },
  async modpacksUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/minecraft/modpacks')
      commit('setModpacks', res.data.modpacks)
    } catch (e: any) {
    }
  },
  async bannerNewsUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/banner-news')
      commit('setBannerNews', res.data.news)
    } catch (e: any) {
    }
  },
  async paymentUpdate ({ commit }) {
    try {
      const res = await this.$axios.get('/minecraft/donate')
      commit('setPayment', res.data)
    } catch (e: any) {
    }
  },
  async fetch ({ getters, commit, dispatch }, force: boolean = false) {
    if (!getters.fetched || force) {
      await Promise.all([
        dispatch('downloadOptionsUpdate'),
        dispatch('monitoringUpdate'),
        dispatch('modpackStatsUpdate'),
        dispatch('modpacksUpdate'),
        dispatch('bannerNewsUpdate')
      ])
      commit('setFetch', true)
    }
  },
  setTheme ({ getters, commit }, mode: 'dark' | 'light' | 'system') {
    const theme: Theme = getters.theme
    let dark = true
    if (mode === 'light') {
      dark = false
    } else if (mode === 'system') {
      dark = theme.osSupport ? theme.osDark : theme.default
    }
    this.$cookies.set('themeMode', mode)
    this.$cookies.set('themeDark', dark)
    commit('setTheme', dark)
    commit('setThemeMode', mode)
  },
  toggleTheme ({ dispatch }) {
    return dispatch('setTheme', this.$vuetify.theme.dark ? 'light' : 'dark')
  },
  resetTheme ({ dispatch }) {
    return dispatch('setTheme', 'system')
  },
  setTimeZone ({ commit }, timeZone: string) {
    commit('setTimeZone', timeZone)
    this.$cookies.set('timeZone', timeZone)
  }
}

// noinspection JSUnusedGlobalSymbols
export const mutations: MutationTree<CoreState> = {
  updateMinecraftUser (state, mcUser: MCUser | {}) {
    Object.assign(state.mcUser, mcUser || {})
  },
  setMinecraftUser (state, mcUser: MCUser | {}) {
    state.mcUser = Object.assign({}, mcUser || {})
  },
  setDownloadOptions (state, downloadOptions: DownloadOptions) {
    state.downloadOptions = Object.assign(state.downloadOptions, downloadOptions || {})
  },
  setMonitoring (state, monitoring: Monitoring) {
    state.monitoring = Object.assign(state.monitoring, monitoring || {})
  },
  setModpackStats (state, modpackStats: ModpackStats) {
    state.modpackStats = Object.assign(state.modpackStats, modpackStats || {})
  },
  addModpack (state, modpack: Modpack) {
    state.modpacks.push(modpack)
  },
  setModpacks (state, modpacks: Modpack[]) {
    state.modpacks.splice(0, state.modpacks.length, ...modpacks)
  },
  addBannerNews (state, bannerNewsItem: BannerNewsItem) {
    state.bannerNews.push(bannerNewsItem)
  },
  setBannerNews (state, bannerNews: BannerNewsItem[]) {
    state.bannerNews.splice(0, state.bannerNews.length, ...bannerNews)
  },
  setFetch (state, fetched: boolean) {
    state.fetched = fetched
  },
  setTimeZone (state, timeZone: string) {
    state.timeZone = timeZone
  },
  setPayment (state, payment: Payment) {
    state.payment = payment
  },
  setTheme (state, dark: boolean) {
    state.theme.dark = dark
  },
  setThemeDefault (state, dark: boolean) {
    state.theme.default = dark
  },
  setThemeMode (state, mode: 'dark' | 'light' | 'system') {
    state.theme.mode = mode
  },
  setThemeOSSupport (state, osSupport: boolean) {
    state.theme.osSupport = osSupport
  },
  setThemeOSDark (state, osDark: boolean) {
    state.theme.osDark = osDark
  }
}
