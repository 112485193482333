import { Plugin } from '@nuxt/types'
import { Go } from '~/types/go'
import { noWait } from '~/utils/misc'

const plugin: Plugin = ({ app, localePath, $makeSad }, inject) => {
  const go: Go = (toPath: string, toHash?: string): void => {
    const router = app.router
    const path = localePath(toPath)
    const hash = toHash ? '#' + toHash : ''
    if (router === undefined) {
      noWait($makeSad())
      return
    }
    router.push({ path, hash }, () => {
      setTimeout(() => {
        if (hash === '') {
          app.$scrollTo('body')
        } else {
          const el = document.querySelector(`a[name="${hash.substring(1)}"]`)
          console.log('Finding', `a[name="${hash.substring(1)}"]`, el)
          if (el) {
            app.$scrollTo(el)
          }
        }
      }, 50)
    })
  }
  inject('go', go)
}

// noinspection JSUnusedGlobalSymbols
export default plugin
